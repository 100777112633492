import React, { useEffect } from "react";
import styled from "styled-components";

import WhyTitleBox from "./Component/WhyTitleBox";
import ExplainBox from "./Component/ExplainBox";
import ThreeStrength from "./Component/ThreeStrength";
import DiffrenceBox from "./Component/DiffrenceBox";
import { commonStyle } from "../../Common/commonStyle";
import { colors } from "../../Utils/colors";

const S = {
  WhyContainer: styled.div`
    ${commonStyle.commonPageContainer}
  `,
  BackgroundContainer: styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
  `,
};
const WhyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <S.WhyContainer>
      <S.BackgroundContainer color={colors.white}>
        <WhyTitleBox />
      </S.BackgroundContainer>
      <S.BackgroundContainer color={colors.bg2}>
        <ExplainBox />
      </S.BackgroundContainer>
      <S.BackgroundContainer color={colors.white}>
        <ThreeStrength />
      </S.BackgroundContainer>
      {/* <DiffrenceBox /> */}
    </S.WhyContainer>
  );
};

export default WhyPage;
