import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    margin-top: 57px;
    display: flex;
    padding: 62px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
    max-width: 1600px;
    margin: 57px auto 0 auto;
    box-sizing: border-box;
  `,
  TxtBox: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    align-self: stretch;
    gap: 48px;
    .BigTxt {
      text-align: left;
      color: ${colors[900]};

      font-size: 4.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 136%; /* 48.96px */
      letter-spacing: -1.26px;
    }
    .SmallTxt {
      text-align: left;
      color: ${colors[900]};

      font-size: 1.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: 156%; /* 28.08px */
      letter-spacing: -0.38px;
    }
  `,
  ImgBox: styled.div`
    align-self: stretch;
    border-radius: 16px;
    & > img {
      width: 100%;
      height: 100%;
    }
  `,
};
