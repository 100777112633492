import CheapfakeImg from "../../Common/CheapfakeImg/CheapfakeImg";
import LinkCopyBtn from "../Common/LinkCopyBtn";
import { S } from "./Style/bannerStyle";

interface BannerProps {
  handleCopy: (text: string) => void;
}

const Banner = ({ handleCopy }: BannerProps) => {
  return (
    <S.Container>
      <S.UpperBox>
        <span className="Normal">
          보험 청구서 위조 여부{"\n"}
          <span className="Purple">AI</span>가 종합 검토해 드려요
        </span>
        <CheapfakeImg name="mobile_main" type="png" />
      </S.UpperBox>
      <LinkCopyBtn handleCopy={handleCopy} />
    </S.Container>
  );
};

export default Banner;
