import styled from "styled-components";
import { colors } from "../../../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: grid;
    gap: 72px;
    grid-template-columns: minmax(auto, 1fr) 1.25fr;

    width: 100%;
    height: 100%;
    box-sizing: border-box;
  `,
  ImageContainer: styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  `,
  UpperBox: styled.div`
    display: flex;
    height: 44px;
    padding: 12px 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 12px 12px 0px 0px;
    border: 1px solid ${colors[300]};
    background: #f6f6f6;
    & > p {
      color: ${colors[700]};
      text-align: center;

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 18px */
      letter-spacing: -0.18px;
    }
  `,

  ImageBox: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    border: 1px solid ${colors[300]};
    background: ${colors[300]};
    aspect-ratio: 1 / 1;

    .Content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .File {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,
  TxtBox: styled.div<{ backgroundcolor: string }>`
    position: absolute;
    top: 50%; /* 부모 컨테이너의 중앙 */
    left: 50%;
    transform: translate(-50%, -50%); /* 중앙 정렬 */
    padding: 8px 40px;
    background: ${(props) => props.backgroundcolor};
    border-radius: 99px;
    color: ${colors.white};
    opacity: 0.85;

    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 18px */
    letter-spacing: -0.18px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap; /* 텍스트가 줄 바꿈되지 않도록 설정 */
  `,
  KonvaContainer: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* 마우스 이벤트가 캔버스에 닿지 않도록 */
    z-index: 2;
  `,
  BottomBox: styled.div`
    display: flex;
    height: 56px;
    padding: 12px 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0px 0px 12px 12px;
    border: 1px solid ${colors[300]};
    position: relative;
    text-align: center;

    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    .Text {
      color: ${colors[900]};
      font-size: 1.8rem;
      letter-spacing: -0.18px;
      margin-right: 12px;
      margin-left: 6px;
    }
    .Plus {
      color: ${colors[600]};
      font-size: 1.5rem;
      letter-spacing: -0.15px;
      cursor: pointer;
    }
  `,
  FormContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .BtnSet {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      margin-left: auto;
      margin-top: 32px;
    }
  `,
  ModalPosition: styled.div`
    position: absolute;
    top: 66px;
    left: 0;
    z-index: 2;
  `,
};
