import React, { useEffect, useRef, useState } from "react";
import { S } from "./Styles/diagnosisformstyle";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { UseFormReturn } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { format } from "date-fns";
import { DiagnosisForm } from "../../../../../Utils/type";
import CheapfakeImg from "../../../../../Common/CheapfakeImg/CheapfakeImg";
import TechExplainModal from "../Modal/TechExplainModal";
interface Props {
  file: string | undefined;
  setIsFilled: (value: boolean) => void;
  onSubmitHandler: (data: DiagnosisForm) => void;
  formMethods: UseFormReturn<DiagnosisForm>;
}

const DiagnosisClaimForm = ({
  file,
  setIsFilled,
  onSubmitHandler,
  formMethods,
}: Props) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);
  const { register, watch, setValue } = formMethods;
  const [isClicked, setIsClicked] = useState<boolean>(false);
  //날짜 바뀔 때
  const handleChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const adjustedStart = start
      ? new Date(start.getTime() - start.getTimezoneOffset() * 60000)
      : undefined;
    const adjustedEnd = end
      ? new Date(end.getTime() - end.getTimezoneOffset() * 60000)
      : undefined;
    setStartDate(adjustedStart);
    setEndDate(adjustedEnd);

    if (adjustedStart && adjustedEnd) {
      setIsOpen(false); // 날짜가 모두 선택되면 달력 닫기
      setValue("start", adjustedStart); // 시작 날짜 값 업데이트
      setValue("end", adjustedEnd); // 종료 날짜 값 업데이트
    }
  };
  //달력 바깥 클릭 시 달력 닫히도록
  const handleClickOutside = (event: MouseEvent) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  // 모든 입력 필드를 모니터링하고, 모든 필드가 채워졌는지 확인
  useEffect(() => {
    const subscription = watch((value) => {
      const { start, end, price, code, etc } = value;
      const allFieldsFilled = start && end && price && code;
      setIsFilled(Boolean(allFieldsFilled));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  //파일 url을 폼 값 상태에 넣기
  useEffect(() => {
    setValue("file", file);
  }, [file]);
  return (
    <S.ClaimBox>
      <S.Title>
        <span>클레임 내용 입력</span>
        <div
          onClick={() => setIsClicked((prev) => !prev)}
          style={{ cursor: "pointer" }}
        >
          <CheapfakeImg name="alert-circle" type="svg" />
        </div>
        {isClicked && (
          <S.ModalPosition>
            <TechExplainModal setIsClicked={setIsClicked} />
          </S.ModalPosition>
        )}
      </S.Title>

      {/* 폼 제출 시 브라우저의 기본 동작을 막고, react-hook-form의 handleSubmit 로직을 실행하기 위해 <form> 태그의 onSubmit 핸들러에 formMethods.handleSubmit을 연결합니다.*/}
      <S.DiagnosisForm onSubmit={formMethods.handleSubmit(onSubmitHandler)}>
        <input type="hidden" {...register("file", { required: true })} />
        <div className="Upper">
          <S.Item>
            <label className="FormLabel" htmlFor="code">
              질병 분류 코드
              <span>*</span>
            </label>
            <input
              {...register("code", { required: true })}
              id="code"
              placeholder="(예시) A09"
              className="FormInput"
            />
          </S.Item>

          <S.Item>
            <label className="FormLabel" htmlFor="price">
              총 진료비
              <span>*</span>
            </label>
            <NumericFormat
              {...register("price", { required: true, valueAsNumber: true })}
              id="price"
              placeholder="(예시) 650,000원"
              className="FormInput"
              thousandSeparator={true}
              suffix=" 원"
              onValueChange={({ floatValue }) =>
                setValue("price", floatValue ?? 0)
              }
            />
          </S.Item>
        </div>
        <S.Item>
          <label className="FormLabel" htmlFor="period">
            진료 기간
            <span>*</span>
          </label>
          <DatePicker
            locale={ko}
            open={isOpen}
            onInputClick={() => {
              setIsOpen(!isOpen);
            }}
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            minDate={new Date("2000-01-01")}
            maxDate={new Date()}
            selectsRange
            shouldCloseOnSelect={false}
            dateFormat="yyyy.MM.dd"
            customInput={
              <S.CustomInputWrapper>
                <input
                  value={
                    startDate && endDate
                      ? `${format(startDate, "yyyy.MM.dd")} - ${format(endDate, "yyyy.MM.dd")}`
                      : ""
                  }
                  placeholder={"(예시) " + format(new Date(), "yyyy.MM.dd")}
                />
                <S.Img
                  src={
                    process.env.REACT_APP_PUBLIC_URL + "/icon/calendardate.png"
                  }
                />
              </S.CustomInputWrapper>
            }
            wrapperClassName="date-picker-wrapper"
            // 달력을 감싸는 요소 설정 및 그 요소에 ref 속성 설정
            popperContainer={({ children }) => (
              <div ref={datePickerRef}>{children}</div>
            )}
          />
          <input type="hidden" {...register("start", { required: true })} />
          <input type="hidden" {...register("end", { required: true })} />
        </S.Item>

        <S.Item>
          <label className="FormLabel" htmlFor="etc">
            기타
          </label>
          <input
            {...register("etc", { required: true })}
            id="etc"
            placeholder="(예시) 차량 사고로 인한 팔목 골절"
            className="FormInput"
          />
        </S.Item>
      </S.DiagnosisForm>
    </S.ClaimBox>
  );
};

export default DiagnosisClaimForm;
