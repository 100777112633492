import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  BannerContainer: styled.div`
    display: flex;
    padding: 68px 48px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    max-width: 1600px;
    margin: 57px auto 0 auto;
    box-sizing: border-box;

    @media ${(props) => props.theme.device.tablet} {
      flex-direction: column;
      justify-content: center;
    }
  `,
  ContentBox: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    @media ${(props) => props.theme.device.tablet} {
      max-width: 100%;
    }
    .BigTxt {
      color: ${colors[900]};

      font-size: 4.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 62.4px */
      letter-spacing: -0.96px;
      white-space: pre-line;
      text-align: left;
      @media ${(props) => props.theme.device.tablet} {
        font-size: 4rem;
        white-space: normal;
      }
    }
    .SmallTxt {
      color: ${colors[900]};

      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 156%; /* 31.2px */
      letter-spacing: -0.2px;
      white-space: pre-line;
      text-align: left;
      @media ${(props) => props.theme.device.tablet} {
        font-size: 1.8rem;
        white-space: normal;
      }
    }
  `,
  BtnSet: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 3rem;
    & > button:nth-child(1) {
      display: flex;
      padding: 20px 32px;

      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: none;
      background: ${colors.primary};
      cursor: pointer;
      &:hover {
        background-color: ${colors.primary5};
      }
      & > p {
        color: ${colors.white};

        font-size: 1.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
    }
    & > button:nth-child(2) {
      display: flex;
      padding: 20px 32px;
      justify-content: center;
      align-items: center;

      border-radius: 6px;
      border: 1px solid ${colors[300]};
      background: ${colors.white};
      cursor: pointer;
      &:hover {
        background-color: ${colors[100]};
      }
      & > p {
        color: ${colors[900]};

        font-size: 1.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
    }
  `,
  VideoBox: styled.div`
    flex: 1;
    max-width: 50%;
    height: auto;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media ${(props) => props.theme.device.tablet} {
      order: -1;
      max-width: 100%;
    }
  `,
};
