import React from "react";
import styled from "styled-components";
import { colors } from "../../Utils/colors";
import LinkCopyBtn from "../Common/LinkCopyBtn";
import { S } from "./Style/noticeStyle";

interface NoticeProps {
  handleCopy: (text: string) => void;
}

const Notice = ({ handleCopy }: NoticeProps) => {
  return (
    <S.Container>
      <div>
        <S.NormalText>
          위변조 기술을 악용한 부당 청구☠️{"\n"}
          <S.PurpleText>SafeClaim</S.PurpleText>과 조기 적발하세요
        </S.NormalText>
      </div>
      <LinkCopyBtn handleCopy={handleCopy} />
    </S.Container>
  );
};

export default Notice;
