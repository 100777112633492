import { useLocation, useNavigate } from "react-router-dom";
import useContact from "../../GlobalState/useContact";
import { S } from "./headerstyle";
import React, { useCallback, useMemo } from "react";
import CheapfakeImg from "../CheapfakeImg/CheapfakeImg";

interface HeaderProps {
  setIsContact: (value: boolean) => void;
  setIsMenu: (value: boolean) => void;
}
//Header에서 문의하기 버튼을 클릭하면 헤더 전체가 리렌더링 되는 이슈 해결
const Header = React.memo(({ setIsContact, setIsMenu }: HeaderProps) => {
  const handleContactClick = useCallback(() => {
    setIsContact(true);
  }, []);
  const handleMenuClick = useCallback(() => {
    setIsMenu(true);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = useMemo(() => {
    return location.pathname.replace("/", "");
  }, [location]);
  return (
    <S.HeaderContainer>
      <S.Logo onClick={() => navigate("/")}>
        <p>SafeClaim</p>
      </S.Logo>
      <S.CenterBox>
        <S.Item isSelected={currentPage === ""} onClick={() => navigate("/")}>
          <p>홈</p>
        </S.Item>
        <S.Item
          isSelected={currentPage === "detection"}
          onClick={() => navigate("/detection")}
        >
          <p>서비스 체험</p>
          <div>
            <p className="item">무료 체험하기</p>
          </div>
        </S.Item>
        <S.Item
          isSelected={currentPage === "why"}
          onClick={() => navigate("/why")}
        >
          <p>SafeClaim 소개</p>
        </S.Item>
        <S.Item
          isSelected={currentPage === "technology"}
          onClick={() => navigate("/technology")}
        >
          <p>AI 기술</p>
        </S.Item>
      </S.CenterBox>
      <S.ContactBtn onClick={handleContactClick}>
        <p>문의하기</p>
      </S.ContactBtn>
      <S.MenuBar onClick={handleMenuClick}>
        <CheapfakeImg name="menu" type="png" />
      </S.MenuBar>
    </S.HeaderContainer>
  );
});

//eslint 오류 해결(display name 부재)
Header.displayName = "Header";

export default Header;
