import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { commonStyle } from "../../../Common/commonStyle";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 72px 40px;

    flex-direction: column;
    justify-content: flex-start;
    gap: 100px;
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
  `,
  Item: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 48px;
  `,
  TxtBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    text-align: left;
    flex: 1 0 0;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .Subject {
        color: ${colors[900]};

        font-size: 3.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 136%; /* 43.52px */
        letter-spacing: -0.64px;
      }
      .Detail {
        color: #2858de;

        font-size: 2.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 136%; /* 24.48px */
        letter-spacing: -0.44px;
      }
    }
    .Explain {
      color: ${colors[700]};

      font-size: 1.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: 156%;
      letter-spacing: -0.19px;
    }
  `,
  ImgBox: styled.div<{ backgroundImage: string }>`
    //크기 비율 16:9로 지정
    ${commonStyle.commonSixteenNineBox}
    background-image: url(${(props) => props.backgroundImage});
    background-size: cover;
    background-position: center;
  `,
};
