import React from "react";
import styled from "styled-components";
import { colors } from "../../Utils/colors";
import CheapfakeImg from "../../Common/CheapfakeImg/CheapfakeImg";
import { S } from "./Style/technologyStyle";

const Technology = () => {
  return (
    <S.Container>
      <S.TextBox>
        <span>서비스 기술력</span>
        <span>SafeClaim의 기술로{"\n"}보험 청구 프로세스를 혁신해요</span>
      </S.TextBox>
      <S.ContentBox>
        <S.Content>
          <CheapfakeImg name="main3" type="png" />
          <S.InfoBox>
            <div>
              <span>고객 데이터를 안전하게</span>
              <span>
                개인정보 <span className="Star">*</span>비식별화 기술로{"\n"}
                고객 데이터를 안전하게 보호해요
              </span>
            </div>
            <span>
              *비식별화 Data Anonymization{"\n"}
              개인을 식별할 수 있는 정보를 제거하거나 변경하는 기술
            </span>
          </S.InfoBox>
        </S.Content>
        <S.Content>
          <CheapfakeImg name="main4" type="png" />
          <S.InfoBox>
            <div>
              <span>
                <span className="Star">*</span>칩페이크 판독 기술력 1위
              </span>
              <span>
                칩페이크 탐지 챌린지 ACM ICMR{"\n"}
                2024에서 1위를 차지했어요
              </span>
            </div>
            <span>
              *칩페이크 Cheapfake{"\n"}
              간단한 편집 기술로 조작한 매체 혹은 AI 기반 조작 기술
            </span>
          </S.InfoBox>
        </S.Content>
        <S.Content>
          <CheapfakeImg name="main5" type="png" />
          <S.InfoBox>
            <div>
              <span>공인 기관 성능 평가 인증</span>
              <span>
                <span className="Star">*</span>KOLAS 인증 기관에서{"\n"}
                시스템 성능을 인정받았어요
              </span>
            </div>
            <span>
              *KOLAS Korea Laboratory Accreditation Scheme{"\n"}
              국제기준에 따라 공인하는 공식 성능 인증 기관
            </span>
          </S.InfoBox>
        </S.Content>
      </S.ContentBox>
    </S.Container>
  );
};

export default Technology;
