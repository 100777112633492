import styled from "styled-components";
import { colors } from "../../../../../../Utils/colors";

export const S = {
  ClaimBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 36px;
    align-self: stretch;
    border-radius: 0px 0px 16px 16px;
    background-color: ${colors.white};
  `,
  Title: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    & > span {
      color: ${colors[900]};

      font-size: 2.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 24.2px */
      letter-spacing: -0.22px;
    }
  `,
  ModalPosition: styled.div`
    position: absolute;
    z-index: 2;
    bottom: 40px;
  `,
  DiagnosisForm: styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 44px;
    align-self: stretch;
    .Upper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
    }
  `,
  Item: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    position: relative;
    gap: 8px;
    .FormInput {
      display: flex;
      padding: 12px;
      border-radius: 6px;
      border: none;
      background-color: ${colors[100]};
      align-self: stretch;

      color: ${colors[700]};

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 136%; /* 24.48px */
      letter-spacing: -0.18px;
      &::placeholder {
        color: ${colors[500]};
      }
    }

    .FormLabel {
      padding: 0 4px;
      color: ${colors[900]};

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 23.4px */
      letter-spacing: -0.18px;
      & > span {
        margin-left: 2px;
        color: ${colors.primary};

        font-size: 1.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 18px */
        letter-spacing: -0.18px;
      }
    }
    .CalendarIcon {
      position: absolute;
      right: 12px;
      top: 50%;
      width: 30px;
      height: 30px;
      pointer-events: none;
    }
  `,
  CustomInputWrapper: styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    background: ${colors[100]};
    & > input {
      background: none;
      width: 100%;
      border: none;
      padding: 12px;
      cursor: pointer;
      color: ${colors[700]};

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 136%; /* 24.48px */
      letter-spacing: -0.18px;
      background: none;
      border: none;
      &::placeholder {
        color: ${colors[500]};
      }
    }
  `,
  Img: styled.img`
    color: black;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    cursor: pointer;
  `,
};
