import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  BoxContainer: styled.div`
    display: flex;
    padding: 72px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
  `,
  Head: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  `,
  Title: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    .ColorTxt {
      color: ${colors.primary};
      text-align: center;

      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 156%; /* 31.2px */
      letter-spacing: -0.2px;
    }
    .BigTxt {
      color: ${colors[900]};
      text-align: center;

      font-size: 3.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 52px */
      letter-spacing: -0.68px;
    }
    .SmallTxt {
      color: ${colors[900]};
      text-align: center;

      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 156%; /* 31.2px */
      letter-spacing: -0.32px;
    }
  `,
  BottomBox: styled.div`
    display: flex;
    padding: 12px 18px;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    border-radius: 99px;
    border: 1px solid ${colors.primary4};
    background: ${colors.white};
    & > span {
      color: ${colors[900]};

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 142%; /* 25.56px */
      letter-spacing: -0.18px;
    }
  `,
};
