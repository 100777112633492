import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 72px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
  `,
  TxtBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    & > p:nth-child(1) {
      color: ${colors[900]};
      text-align: center;

      font-size: 3.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 136%; /* 48.96px */
      letter-spacing: -0.68px;
    }
    & > p:nth-child(2) {
      color: ${colors[900]};
      text-align: center;

      font-size: 2.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 156%; /* 34.32px */
      letter-spacing: -0.384px;
    }
  `,
  ImgBox: styled.div`
    align-self: stretch;
    border-radius: 16px;
    & > img {
      width: 100%;
      height: 100%;
    }
  `,
};
