import React, { useEffect } from "react";
import MainBanner from "./Component/MainBanner";
import TryBox from "./Component/TryBox";
import ProcessDiagram from "./Component/ProcessDiagram";
import AdvantageBox from "./Component/AdvantageBox";
import styled from "styled-components";
import { commonStyle } from "../../Common/commonStyle";
import { colors } from "../../Utils/colors";

const S = {
  MainContainer: styled.div`
    ${commonStyle.commonPageContainer}
  `,
  BackgroundContainer: styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
  `,
};
const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <S.MainContainer>
      <S.BackgroundContainer color={colors.white}>
        <MainBanner />
      </S.BackgroundContainer>
      <S.BackgroundContainer color={colors.bg2}>
        <TryBox />
      </S.BackgroundContainer>
      <S.BackgroundContainer color={colors.white}>
        <ProcessDiagram />
      </S.BackgroundContainer>
      <S.BackgroundContainer color={colors.bg2}>
        <AdvantageBox />
      </S.BackgroundContainer>
    </S.MainContainer>
  );
};

export default MainPage;
