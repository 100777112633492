import React, { useEffect } from "react";
import styled from "styled-components";

import TutorialBox from "./Component/TutorialBox";
import { commonStyle } from "../../Common/commonStyle";
import UploadBox from "./Component/DetectionBox/UploadBox";

import { colors } from "../../Utils/colors";
import MainBox from "./Component/MainBox";
const S = {
  DetectionContainer: styled.div`
    ${commonStyle.commonPageContainer}
  `,
  BackgroundContainer: styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
  `,
};
const DetectionPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <S.DetectionContainer>
      <S.BackgroundContainer color={colors[100]}>
        <MainBox />
      </S.BackgroundContainer>
      {/* <UploadBox /> */}
    </S.DetectionContainer>
  );
};

export default DetectionPage;
