import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { commonStyle } from "../../../Common/commonStyle";

export const S = {
  AdvantageContainer: styled.div`
    display: flex;
    padding: 72px 48px;
    gap: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    box-sizing: border-box;
    margin: 0 auto;
    & > p {
      align-self: stretch;
      color: ${colors[900]};
      text-align: center;

      font-size: 3.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 136%;
      letter-spacing: -0.72px;
    }
  `,
  ItemBox: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @media ${(props) => props.theme.device.tablet} {
      grid-template-columns: none;
      grid-template-rows: repeat(3, 1fr);
    }
  `,
  Item: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
    img {
      max-width: 100%;
      height: auto;
      max-height: 300px; /* 모든 이미지의 최대 높이 제한 */
      object-fit: contain;
    }
    @media ${(props) => props.theme.device.tablet} {
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }
  `,
  TxtBox: styled.div`
    .BoldText {
      color: ${colors[900]};
      text-align: center;

      font-size: 2.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 30.8px */
      letter-spacing: -0.44px;
      .Star {
        font-weight: 700;
        color: ${colors.primary};
      }
    }
    .NormalText {
      margin-top: 10px;
      color: ${colors[900]};
      text-align: center;

      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 156%; /* 30.8px */
      letter-spacing: -0.32px;

      .Star {
        font-weight: 400;
        color: ${colors.primary};
      }
    }
  `,
  InfoBox: styled.div`
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-top: 1px solid ${colors[400]};
    & > span {
      color: ${colors[700]};

      font-size: 1.7rem;
      font-style: normal;
      font-weight: 500;
      line-height: 142%; /* 24.14px */
      letter-spacing: -0.17px;
    }
  `,
};
