import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../Utils/colors";
import CheapfakeImg from "../../Common/CheapfakeImg/CheapfakeImg";
import { S } from "./Style/qnaStyle";

const QnABox = () => {
  const [isOpenIndexes, setIsOpenIndexes] = useState<boolean[]>([]);

  const toggleOpen = (index: number) => {
    setIsOpenIndexes((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const questions = [
    "SafeClaim은 어떤 서비스인가요?",
    "어떤 기능을 제공하나요?",
    "커스텀 가능한가요?",
    "얼마나 정확한가요?",
    "결과가 어떤 형태로 나오나요?",
  ];
  const answers = [
    "SafeClaim은 AI를 활용해 클레임 데이터를 분석하고 위변조를 감지하여 보험금 부정 청구를 방지하는 서비스예요.",
    "현재 체험판에서는 병원진단서와 차량 사고부위 인식을 지원하며 판별 결과 알림 기능을 제공해요. 추후에는 클레임 데이터의 맞춤형 인식과 프로세스 자동화 기능을 제공할 예정이에요.",
    "네, 보험사가 특정 데이터를 직접 지정할 수 있도록 커스텀 기능을 제공할 예정이에요. 예를 들어, 병원 진단서의 날짜와 청구서의 진단 일자를 비교하고 싶을 때 해당 데이터를 지정할 수 있어요. 또한, 사고 경위에 대한 문맥을 분석하고자 할 경우 AI가 '사고 경위' 텍스트의 맥락을 파악해 집중적으로 분석해줘요.",
    "SafeClaim은 보험사 관계자들의 요구사항을 바탕으로 모델 정확도 분석 알고리즘을 개발하여 정확도를 향상시켰어요. 그 결과, 2024년 10월에 KOLAS 인정 기관에서 이미지 위조 여부 판별에 91%의 정확도를 인정받았어요. 또한, SafeClaim은 2024년 ACM ICMR 칩페이크(Cheapfake) 탐지 챌린지에서 1위를 차지하기도 했어요.",
    `위조 확률에 따라 3단계로 알림이 제공돼요.${"\n"}1단계(안전) : 위조 확률이 낮음${"\n"}2단계(주의) : 위조 확률이 높음${"\n"}3단계(위험) : 위조 확률이 매우 높음`,
  ];
  return (
    <S.Container>
      {questions.map((question, index) => (
        <S.ContentWrapper key={index}>
          <S.Content onClick={() => toggleOpen(index)}>
            <span>Q.</span>
            <span>{question}</span>
            <CheapfakeImg
              name={isOpenIndexes[index] ? "chevron-up" : "chevron-down"}
              type="svg"
            />
          </S.Content>
          <S.AnswerBox isOpen={isOpenIndexes[index]}>
            <span>{answers[index]}</span>
          </S.AnswerBox>
        </S.ContentWrapper>
      ))}
    </S.Container>
  );
};

export default QnABox;
