import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { S } from "./Styles/uploadboxstyle";
import CheapfakeImg from "../../../../Common/CheapfakeImg/CheapfakeImg";

interface UploadProps {
  handleDiagnosisUpload: (file: File) => void; // 파일 객체를 직접 받도록 수정
  handleCarisUpload: (file: File) => void; // 파일 객체를 직접 받도록 수정
}

const UploadBox = ({
  handleDiagnosisUpload,
  handleCarisUpload,
}: UploadProps) => {
  // const diagnosisImageRefs = useRef<HTMLDivElement[]>([]);
  // const carImageRefs = useRef<HTMLDivElement[]>([]);

  // const diagnosisImages = [
  //   { name: "sample1", type: "png" },
  //   { name: "sample2", type: "png" },
  // ];
  // const carImages = [
  //   { name: "sample3", type: "png" },
  //   { name: "sample4", type: "png" },
  // ];

  // // 이미지 태그에서 File 객체 생성
  // const handleSampleClick = async (
  //   ref: HTMLDivElement | null,
  //   isCarImage: boolean,
  // ) => {
  //   if (!ref) return;

  //   const imgElement = ref.querySelector("img");
  //   if (!imgElement) return;

  //   const canvas = document.createElement("canvas");
  //   const ctx = canvas.getContext("2d");
  //   if (!ctx) return;
  //   const { naturalWidth, naturalHeight } = imgElement;
  //   // 이미지 크기 설정
  //   canvas.width = naturalWidth;
  //   canvas.height = naturalHeight;

  //   // Canvas에 이미지 그리기
  //   ctx.drawImage(imgElement, 0, 0);

  //   // Canvas를 Blob객체로 변환
  //   const blob = await new Promise<Blob | null>((resolve) =>
  //     canvas.toBlob(resolve, "image/png"),
  //   );
  //   if (!blob) return;

  //   // Blob을 File로 변환
  //   const file = new File([blob], `${imgElement.src.split("/").pop()}`, {
  //     type: "image/png",
  //   });

  //   //이후 upload handler 호출
  //   if (isCarImage) {
  //     handleCarisUpload(file);
  //   } else {
  //     handleDiagnosisUpload(file);
  //   }
  // };

  return (
    <>
      <S.UploadHead>
        <span>이미지 첨부</span>
        <span>*파일 용량 제한 10MB 이하</span>
      </S.UploadHead>
      <S.ItemContainer>
        <S.Item>
          <div>
            <CheapfakeImg name="document" type="svg" />
            <p>병원 진단서 이미지</p>
          </div>
          <S.UploadBtn>
            <CheapfakeImg name="upload" type="svg" />
            <p>첨부하기</p>
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  handleDiagnosisUpload(file);
                }
              }}
            />
          </S.UploadBtn>
        </S.Item>
        <p>or</p>
        <S.Item>
          <div>
            <CheapfakeImg name="caracc" type="svg" />
            <p>자동차 사고 이미지</p>
          </div>
          <S.UploadBtn>
            <CheapfakeImg name="upload" type="svg" />
            <p>첨부하기</p>
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  handleCarisUpload(file);
                }
              }}
            />
          </S.UploadBtn>
        </S.Item>
      </S.ItemContainer>
      {/* <S.ExampleBox>
        {diagnosisImages.map((image, index) => (
          <S.ExampleItem
            key={image.name}
            //el 이 undefiend나 null 이 아니라고 보장
            ref={(el) => (diagnosisImageRefs.current[index] = el!)} // 진단서 이미지에 대한 ref
            onClick={() => {
              handleSampleClick(diagnosisImageRefs.current[index], false); // 이미지 변환 및 업로드
            }}
          >
            <CheapfakeImg name={image.name} type={image.type} />
          </S.ExampleItem>
        ))}
        {carImages.map((image, index) => (
          <S.ExampleItem
            key={image.name}
            //el 이 undefiend나 null 이 아니라고 보장
            ref={(el) => (carImageRefs.current[index] = el!)} // 자동차 이미지에 대한 ref
            onClick={() => {
              handleSampleClick(carImageRefs.current[index], true); // 이미지 변환 및 업로드
            }}
          >
            <CheapfakeImg name={image.name} type={image.type} />
          </S.ExampleItem>
        ))}
      </S.ExampleBox> */}
      <S.TextBox>
        <CheapfakeImg name="alert-circle-purple" type="svg" />
        <span>
          첨부하기 버튼 클릭 시{" "}
          <span
            className="Focus"
            onClick={() =>
              window.open(
                "https://safe-ai.notion.site/106f5deaf68a80f2a541e24edfeb11a9?pvs=4",
              )
            }
          >
            개인정보처리방침
          </span>
          에 동의하게 됩니다
        </span>
      </S.TextBox>
    </>
  );
};

export default UploadBox;
