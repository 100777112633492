import React from "react";
import { create } from "zustand";

interface Menu {
  isMenu: boolean;
  setIsMenu: (value: boolean) => void;
}

const useMenu = create<Menu>((set) => ({
  isMenu: false,
  setIsMenu: (value: boolean) => set({ isMenu: value }),
}));

export default useMenu;
