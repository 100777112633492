import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  ProcessContainer: styled.div`
    display: flex;
    padding: 72px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
  `,
  Head: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    .BigTxt {
      color: ${colors[900]};
      text-align: center;

      font-size: 3.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 136%;
      letter-spacing: -0.72px;
    }
    .SmallTxt {
      color: ${colors[900]};
      text-align: center;

      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 156%;
      letter-spacing: -0.32px;
    }
    & > button {
      display: flex;
      padding: 16px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid ${colors[300]};
      background-color: ${colors.white};
      cursor: pointer;
      &:hover {
        background-color: ${colors[100]};
      }
      & > p {
        color: ${colors[900]};

        font-size: 1.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }
    }
  `,
  ImgBox: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 13.095px;
    width: 100%;
    @media ${(props) => props.theme.device.tablet} {
      grid-template-columns: repeat(2, 1fr);
    }
    & > img {
      width: 100%;
    }
  `,
};
