import React from "react";
import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { S } from "../Styles/contentstyle";

const TechContent = () => {
  return (
    <S.Container>
      <S.Item>
        {/* 1 */}
        <S.TxtBox>
          <div>
            <p className="Detail">Forgery detection model</p>
            <p className="Subject">자체 개발 AI 모델 활용 분석</p>
          </div>
          <p className="Explain">
            단순한 이미지 조작도 육안으로는 식별하기 어려운 경우가 많기 때문에
            다양한 기술을 활용한 검토가 필요해요. SafeClaim은 자체 개발한 멀티
            스테이지 위조 검출 모델(Forgery detection model)을 활용해 이미지
            내에 교묘한 위조까지 AI 모델을 활용해 감지할 수 있어요.
          </p>
        </S.TxtBox>

        <S.ImgBox
          backgroundImage={process.env.REACT_APP_PUBLIC_URL + "/icon/tech2.png"}
        />
      </S.Item>
      {/* 2 */}
      <S.Item>
        <S.ImgBox
          backgroundImage={process.env.REACT_APP_PUBLIC_URL + "/icon/tech3.png"}
        />

        <S.TxtBox>
          <div>
            <p className="Detail">Multimodal Model</p>
            <p className="Subject">멀티모달 모델 활용 분석</p>
          </div>
          <p className="Explain">
            SafeClaim의 기술로 클레임 내 이미지와 텍스트 등 다양한 형태의 위조
            데이터를 보다 정밀하게 탐지할 수 있어요. 멀티모달 모델(Multimodal
            model)을 활용하여 증빙 서류의 텍스트와 클레임 데이터 내 텍스트가
            맥락적으로 유사한지 AI가 스마트하게 추론하고 검토해줘요.
          </p>
        </S.TxtBox>
      </S.Item>
    </S.Container>
  );
};

export default TechContent;
