import React from "react";
import styled from "styled-components";
import { colors } from "../../Utils/colors";
import QnABox from "./QnABox";
import { S } from "./Style/questionStyle";

const Question = () => {
  return (
    <S.Container>
      <S.Subject>자주 묻는 질문</S.Subject>
      <QnABox />
      <S.ContactBtn onClick={() => window.open("https://tally.so/r/mVl4vl")}>
        <span>문의하기</span>
      </S.ContactBtn>
    </S.Container>
  );
};

export default Question;
