import React from "react";
import { S } from "../Styles/advantagestyle";
import CheapfakeImg from "../../../Common/CheapfakeImg/CheapfakeImg";

const AdvantageBox = () => {
  return (
    <S.AdvantageContainer>
      <p>
        SafeClaim의 기술력으로
        <br />
        보험 청구 프로세스를 혁신해요
      </p>

      <S.ItemBox>
        <S.Item>
          <CheapfakeImg name="main3" type="png" />
          <S.TxtBox>
            <p className="BoldText">고객 프라이버시 보호</p>
            <p className="NormalText">
              개인정보 비식별화
              <span className="Star">*</span>
              기술로
              <br />
              고객 데이터를 철저히 보호해요
            </p>
          </S.TxtBox>
        </S.Item>
        <S.Item>
          <CheapfakeImg name="main4" type="png" />
          <S.TxtBox>
            <p className="BoldText">
              칩페이크<span className="Star">*</span> 판독 기술력 1위
            </p>
            <p className="NormalText">
              칩페이크 탐지 챌린지
              <br />
              ACM ICMR 2024에서 수상했어요
            </p>
          </S.TxtBox>
        </S.Item>
        <S.Item>
          <CheapfakeImg name="main5" type="png" />
          <S.TxtBox>
            <p className="BoldText">공인 기관 성능 평가 인증</p>
            <p className="NormalText">
              KOLAS<span className="Star">*</span> 인증 기관에서
              <br />
              시스템 성능을 인정받았어요
            </p>
          </S.TxtBox>
        </S.Item>
      </S.ItemBox>
      <S.InfoBox>
        <span>
          - 비식별화(DataAnonymization): 개인을 식별할 수 있는 정보를 제거하거나
          변경하여 특정 개인과 연결할 수 없도록 만드는 기술
        </span>
        <span>- 칩페이크(Cheapfake): 간단한 편집 기술로 만든 조작된 매체</span>
        <span>
          - KOLAS(Korea Laboratory Accreditation Scheme): 국제표준화기구에서
          정한 국제기준에 따라 평가하여 공인하는 공식 인증 기관
        </span>
      </S.InfoBox>
    </S.AdvantageContainer>
  );
};

export default AdvantageBox;
