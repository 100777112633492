import React from "react";
import { S } from "../Styles/bannerstyle";
import { useNavigate } from "react-router-dom";

const MainBanner = () => {
  const navigate = useNavigate();
  return (
    <S.BannerContainer>
      <S.ContentBox>
        <p className="BigTxt">
          보험 청구서 위조 가능성 {"\n"} 이미지 한 장으로 {"\n"} 알아낼 수
          있어요
        </p>
        <p className="SmallTxt">
          SafeClaim은 보험금 부정 청구 방지를 위해{"\n"} 조작된 클레임 데이터를
          검토해 주는 AI 서비스입니다
        </p>
        <S.BtnSet>
          <button onClick={() => navigate("/detection")}>
            <p>무료 체험하기</p>
          </button>
          <button onClick={() => navigate("/why")}>
            <p>더 알아보기</p>
          </button>
        </S.BtnSet>
      </S.ContentBox>
      <S.VideoBox>
        <video autoPlay loop muted playsInline preload="auto">
          <source
            src={process.env.REACT_APP_PUBLIC_URL + "/mainvideo.webm"}
            type="video/webm"
          />
        </video>
      </S.VideoBox>
    </S.BannerContainer>
  );
};

export default MainBanner;
