import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 44px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    box-sizing: border-box;
    background: ${colors[100]};
  `,
  Subject: styled.span`
    color: ${colors[900]};
    text-align: center;

    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: -0.6px;
  `,
  ContactBtn: styled.div`
    display: flex;
    padding: 20px 36px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${colors[900]};
    background: ${colors.white};
    width: 230px;
    cursor: pointer;
    & > span {
      color: ${colors[900]};

      font-size: 1.6rem;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
    }
  `,
};
