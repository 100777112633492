import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 44px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    box-sizing: border-box;
  `,
  MainTextBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    & > span:nth-child(1) {
      color: ${colors.primary};
      text-align: center;

      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.544px;
    }
    & > span:nth-child(2) {
      color: ${colors[900]};
      text-align: center;

      font-size: 2.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 36px */
      letter-spacing: -0.72px;
      white-space: pre-line;
    }
  `,
  ContentBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  `,
  SubjectText: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .PurpleBox {
      display: flex;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: ${colors.primary2};
      & > span {
        color: ${colors.primary};
        text-align: center;

        font-size: 1.3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 160%; /* 20.8px */
        letter-spacing: -0.26px;
      }
    }
    .Normal {
      color: ${colors[900]};
      text-align: center;

      font-size: 1.7rem;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 27.2px */
      letter-spacing: -0.51px;
      white-space: pre-line;
    }
  `,
};
