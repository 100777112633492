import React from "react";
import styled from "styled-components";
import { colors } from "../../Utils/colors";

interface ButtonProps {
  handleCopy: (text: string) => void;
}

const S = {
  LowerBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    & > span {
      color: ${colors[700]};
      text-align: center;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: -0.28px;
    }
  `,
  Button: styled.div`
    display: flex;
    padding: 20px 36px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: ${colors.primary};

    border: none;
    & > span {
      color: ${colors.white};

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
    }
  `,
};
const LinkCopyBtn = ({ handleCopy }: ButtonProps) => {
  return (
    <S.LowerBox>
      <span>PC, 태블릿 환경에서 직접 체험해 보세요</span>
      <S.Button
        onClick={() => {
          handleCopy("https://safeclaim.safeai.kr");
        }}
      >
        <span>PC, 태블릿 링크 복사하기</span>
      </S.Button>
    </S.LowerBox>
  );
};

export default LinkCopyBtn;
