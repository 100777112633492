import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { commonStyle } from "../../../Common/commonStyle";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 60px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 60px;
    align-self: stretch;
    max-width: 1600px;
    margin: 57px auto 0 auto;
    box-sizing: border-box;
  `,
  TxtBox: styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    align-self: stretch;
    gap: 48px;
    & > p:nth-child(1) {
      text-align: left;
      color: ${colors[900]};

      font-size: 4.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 136%; /* 48.96px */
      letter-spacing: -1.26px;
    }
    & > p:nth-child(2) {
      text-align: left;
      color: ${colors[900]};

      font-size: 1.9rem;
      font-style: normal;
      font-weight: 400;
      line-height: 156%; /* 28.08px */
      letter-spacing: -0.38px;
    }
  `,
  ItemBox: styled.div`
    display: flex;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
  `,
  Item: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    .Subject {
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      .NumBox {
        width: 24px;
        height: 24px;
        border-radius: 6px;
        background-color: black;
        color: ${colors.white};
        text-align: center;

        font-size: 1.8rem;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 23.4px */
        letter-spacing: -0.36px;
      }
      .Text {
        color: ${colors[900]};

        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 22px */
        letter-spacing: -0.4px;
      }
    }

    .Additional {
      color: ${colors[600]};

      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      letter-spacing: -0.14px;
    }
  `,
  ImgBox: styled.div<{ backgroundImage: string }>`
    //크기 비율 16:9로 지정
    ${commonStyle.commonSixteenNineBox}
    background-image: url(${(props) => props.backgroundImage});
    background-size: cover;
    background-position: center;
  `,
};
