import styled from "styled-components";
import { colors } from "../../Utils/colors";

export const S = {
  BackgroundContainer: styled.div`
    background-color: ${colors[200]};
  `,
  FooterContainer: styled.div`
    display: flex;
    padding: 72px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 36px;
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
  `,
  FooterTab: styled.div`
    display: flex;
    height: 32px;
    align-items: center;
    gap: 6px;
    align-self: stretch;
  `,
  LogoBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > * {
      width: 115px;
      height: 29px;
    }
  `,
  ItemBox: styled.div`
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
    gap: 32px;
  `,
  Item: styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    gap: 48px;
    cursor: pointer;

    & > p {
      color: ${colors[700]};
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 28px */
      letter-spacing: -0.17px;
    }
  `,

  InfoBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    & > div {
      display: flex;
      align-items: center;
      gap: 6px;
      & > p {
        color: ${colors[700]};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.14px;
      }
      .Both {
        border-width: 0 1px 0;
        border-color: ${colors[500]};
        border-style: solid;
        padding: 0 8px;
      }
      .Right {
        border-width: 0 1px 0 0;
        border-color: ${colors[500]};
        border-style: solid;
        padding: 0 8px 0 0;
        letter-spacing: -2%;
      }
    }
  `,

  Footer: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 1px solid ${colors[500]};
    padding-top: 28px;
    width: 100%;
    & > p {
      color: ${colors[700]};

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 16px */
      letter-spacing: -0.14px;
    }
    & > p:nth-child(2) {
      border-width: 0 1px 0;
      border-color: ${colors[500]};
      border-style: solid;
      padding: 0 8px;
    }
    .Link {
      cursor: pointer;
    }
  `,
};
