import React from "react";
import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { S } from "../Styles/processstyle";
import { useNavigate } from "react-router-dom";
import CheapfakeImg from "../../../Common/CheapfakeImg/CheapfakeImg";

const ProcessDiagram = () => {
  const navigate = useNavigate();
  return (
    <S.ProcessContainer>
      <S.Head>
        <p className="BigTxt">
          클레임 데이터의 조작 여부와 일관성을
          <br />
          AI가 종합적으로 판단해요
        </p>
        <p className="SmallTxt">
          SafeClaim은 이미지와 텍스트를 동시에 검토할 수 있어요
        </p>
        {/* <button onClick={() => navigate("/why")}>
          <p>더 알아보기</p>
        </button> */}
      </S.Head>
      {/* src={process.env.REACT_APP_PUBLIC_URL + "/icon/main2.png"} */}
      <S.ImgBox>
        {/* <CheapfakeImg name="" type="" />
        <CheapfakeImg name="" type="" />
        <CheapfakeImg name="" type="" />
        <CheapfakeImg name="" type="" /> */}
        <CheapfakeImg name="technology_flow" type="png" />
        <CheapfakeImg name="technology_flow2" type="png" />
        <CheapfakeImg name="technology_flow3" type="png" />
        <CheapfakeImg name="technology_flow4" type="png" />
      </S.ImgBox>
    </S.ProcessContainer>
  );
};

export default ProcessDiagram;
