import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { commonStyle } from "../../../Common/commonStyle";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 72px 40px;

    flex-direction: column;
    justify-content: flex-start;
    align-self: stretch;
    gap: 100px;
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
  `,
  Item: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 48px;
  `,
  TxtBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    text-align: left;
    flex: 1 0 0;
    .TxtFocus {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .Green {
        color: #0c5872;
      }
      .Blue {
        color: #285bde;
      }
      .Purple {
        color: #7b267d;
      }
      & > p:nth-child(1) {
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 600;
        line-height: 136%; /* 24.48px */
        letter-spacing: -0.48px;
      }
      & > p:nth-child(2) {
        color: ${colors[900]};

        font-size: 3.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 136%; /* 43.52px */
        letter-spacing: -0.64px;
      }
    }
    .Detail {
      color: ${colors[700]};

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 156%; /* 28.08px */
      letter-spacing: -0.18px;
    }
  `,
  ImgBox: styled.div<{ backgroundImage: string }>`
    //크기 비율 16:9로 지정
    ${commonStyle.commonSixteenNineBox}
    background-image: url(${(props) => props.backgroundImage});
    background-size: cover;
    background-position: center;
  `,
};
