import React from "react";
import { createGlobalStyle, css } from "styled-components";
import { colors } from "../Utils/colors";

export const commonStyle = {
  commonPageContainer: css`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: auto;
    /* max-width: 1600px; */
    align-items: stretch;
    margin: 0 auto; /* 수평 가운데 정렬 */
  `,
  commonSixteenNineBox: css`
    width: 100%;
    //크기 비율 16:9로 지정
    height: 0;
    padding-bottom: 56.25%;
    border-radius: 16px;
    position: relative;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `,
};
