import React from "react";
import TryCards from "./TryCards";
import { S } from "../Styles/trystyle";
import { useNavigate } from "react-router-dom";
import CheapfakeImg from "../../../Common/CheapfakeImg/CheapfakeImg";

const TryBox = () => {
  const navigate = useNavigate();
  return (
    <S.BoxContainer>
      <S.Head>
        <S.Title>
          <p className="ColorTxt">서비스 미리보기</p>
          <p className="BigTxt">어떤 사진이 조작되었을까요?</p>
          <p className="SmallTxt">SafeClaim의 기술로 쉽게 판별해 보세요</p>
        </S.Title>
      </S.Head>
      <TryCards />
      <S.BottomBox>
        <CheapfakeImg name="light" type="png" />
        <span>
          SafeClaim는 육안으로 식별하기 어려운 미세한 조작도 탐지할 수 있어요
        </span>
      </S.BottomBox>
    </S.BoxContainer>
  );
};

export default TryBox;
