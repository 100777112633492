import React from "react";
import styled from "styled-components";
import { colors } from "../../Utils/colors";
import CheapfakeImg from "../../Common/CheapfakeImg/CheapfakeImg";
import LinkCopyBtn from "../Common/LinkCopyBtn";
import Lottie from "lottie-react";
import animation1 from "../Animation/animation.json";
import animation2 from "../Animation/animation2.json";
import { S } from "./Style/strengthStyle";
interface StrengthProps {
  handleCopy: (text: string) => void;
}

const Strength = ({ handleCopy }: StrengthProps) => {
  return (
    <S.Container>
      <S.MainTextBox>
        <span>SafeClaim이 먼저</span>
        <span>클레임 데이터를 분석하고{"\n"}위변조를 감지해요</span>
      </S.MainTextBox>
      <S.ContentBox>
        <S.SubjectText>
          <div className="PurpleBox">
            <span>똑똑한 인공지능 서비스</span>
          </div>
          <span className="Normal">
            이미지와 텍스트 간 맥락을 이해하며{"\n"}위조 여부를 판단해요
          </span>
        </S.SubjectText>

        <Lottie animationData={animation1} />
      </S.ContentBox>
      <S.ContentBox>
        <S.SubjectText>
          <div className="PurpleBox">
            <span>빠른 조기 적발</span>
          </div>
          <span className="Normal">
            데이터 위조 확률이 높을 경우{"\n"} 담당자에게 알림을 전송해요
          </span>
        </S.SubjectText>

        <Lottie animationData={animation2} />
      </S.ContentBox>
      <LinkCopyBtn handleCopy={handleCopy} />
    </S.Container>
  );
};

export default Strength;
