import React from "react";
import { create } from "zustand";

interface Contact {
  isContact: boolean;
  setIsContact: (value: boolean) => void;
}

const useContact = create<Contact>((set) => ({
  isContact: false,
  setIsContact: (value) => set({ isContact: value }),
}));

export default useContact;
