import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 24px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    align-self: stretch;
    border-radius: 12px;
    background: ${colors.white};
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
  `,
  Content: styled.div`
    display: flex;
    padding: 4px 20px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    text-align: left;
    & > span:nth-child(1) {
      color: ${colors.primary};
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 15px */
      letter-spacing: -0.3px;
    }
    & > span:nth-child(2) {
      flex: 1 0 0;
      color: ${colors[900]};

      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 15px */
      letter-spacing: -0.3px;
    }
  `,
  AnswerBox: styled.div<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    padding: 8px 20px;
    align-items: center;

    & > span {
      flex: 1 0 0;
      color: ${colors[700]};

      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 20.8px */
      letter-spacing: -0.39px;
      text-align: left;
    }
  `,
};
