import React from "react";
import styled from "styled-components";
import CheapfakeImg from "../../Common/CheapfakeImg/CheapfakeImg";
import { colors } from "../../Utils/colors";
import { S } from "./Style/mobileFooterStyle";

const MobileFooter = () => {
  return (
    <S.Container>
      <CheapfakeImg name="SafeAILogoSmall" type="svg" />
      <S.ContentBox>
        <S.InfoBox>
          <span>세이프에이아이</span>
          <span>대표 이원섭</span>
          <span>서울시 강남구 영동대로 324 804호</span>
          <span>contact@safeai.kr</span>
          <span>개인정보관리책임자 김원석</span>
        </S.InfoBox>
        <S.SubBox>
          <S.SubContent>
            <div>
              <span className="SubText">개인정보처리방침</span>
              <CheapfakeImg name="vector101" type="png" />
              <span className="SubText">서비스 이용약관</span>
            </div>
            <span className="SubText">
              © 2024 SafeAI Inc. All Rights Reserved.
            </span>
          </S.SubContent>
        </S.SubBox>
      </S.ContentBox>
    </S.Container>
  );
};

export default MobileFooter;
