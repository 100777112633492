import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { colors } from "../../Utils/colors";
import { useLocation, useNavigate } from "react-router-dom";
import CheapfakeImg from "../CheapfakeImg/CheapfakeImg";
import useContact from "../../GlobalState/useContact";
import useMenu from "../../GlobalState/useMenu";
const S = {
  BackgroundOverlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(38, 38, 44, 0.5);
    z-index: 5;
  `,
  MenuContainer: styled.div`
    z-index: 10;
    display: flex;
    width: 100%;
    padding: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 28px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${colors.white};
  `,
  MenuContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  `,
  Item: styled.div<{ isSelected: boolean }>`
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 8px;
    gap: 8px;
    cursor: pointer;
    & > p {
      color: ${(props) => (props.isSelected ? colors.primary : colors[900])};

      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.18px;
    }
    & > div {
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: ${colors.primary2};
      .item {
        color: ${colors.primary};

        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -0.13px;
      }
    }
  `,
  ContactBtn: styled.button`
    display: flex;
    padding: 16px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: ${colors.primary};
    border: none;
    cursor: pointer;
    & > span {
      color: ${colors.white};

      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 19.8px */
      letter-spacing: -0.18px;
    }
  `,
  CloseBtn: styled.button`
    position: absolute;
    right: 48px;
    top: 22px;
    border: none;
    background: none;
    cursor: pointer;
  `,
};
const DrawerMenu = () => {
  const { isContact, setIsContact } = useContact();
  const { isMenu, setIsMenu } = useMenu();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = useMemo(() => {
    return location.pathname.replace("/", "");
  }, [location]);

  const handleContactClick = useCallback(() => {
    setIsContact(true);
    setIsMenu(false);
  }, []);
  const handleNavigate = useCallback(
    (path: string) => {
      navigate(path);
      setIsMenu(false);
    },
    [navigate, setIsMenu],
  );
  return (
    <>
      <S.BackgroundOverlay onClick={() => setIsMenu(false)} />
      <S.MenuContainer>
        <S.MenuContent>
          <S.Item
            isSelected={currentPage === ""}
            onClick={() => handleNavigate("/")}
          >
            <p>홈</p>
          </S.Item>
          <S.Item
            isSelected={currentPage === "detection"}
            onClick={() => handleNavigate("/detection")}
          >
            <p>서비스 체험</p>
            <div>
              <p className="item">무료 체험하기</p>
            </div>
          </S.Item>
          <S.Item
            isSelected={currentPage === "why"}
            onClick={() => handleNavigate("/why")}
          >
            <p>SafeClaim 소개</p>
          </S.Item>
          <S.Item
            isSelected={currentPage === "technology"}
            onClick={() => handleNavigate("/technology")}
          >
            <p>AI 기술</p>
          </S.Item>
        </S.MenuContent>
        <S.ContactBtn onClick={handleContactClick}>
          <span>문의하기</span>
        </S.ContactBtn>
        <S.CloseBtn onClick={() => setIsMenu(false)}>
          <CheapfakeImg name="modal_x" type="png" />
        </S.CloseBtn>
      </S.MenuContainer>
    </>
  );
};

export default DrawerMenu;
