import "./App.css";

import { GlobalStyle } from "./Common/datepickerstyle";
import { ThemeProvider } from "styled-components";
import TagManager from "react-gtm-module";
import theme from "./Utils/theme";
import AppRouter from "./Router";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-TZ6VG9K8" });
  }, []);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AppRouter />
      </ThemeProvider>
    </div>
  );
};

export default App;
