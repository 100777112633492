import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../Utils/colors";
import CheapfakeImg from "../../Common/CheapfakeImg/CheapfakeImg";
import { S } from "./Style/previewStyle";

const Preview = () => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };
  const paths = [
    process.env.REACT_APP_PUBLIC_URL + "/icon/default1.png",
    process.env.REACT_APP_PUBLIC_URL + "/icon/selectedmobile.png",
  ];
  return (
    <S.Container>
      <S.TextBox>
        <div className="SubjectBox">
          <span>서비스 미리보기</span>
          <span>병원진단서가 조작되었을까요?</span>
        </div>
      </S.TextBox>
      <S.BottomBox>
        <S.TextBalloon>
          <span>이미지를 터치해 보세요</span>
        </S.TextBalloon>
        <div onClick={handleCardClick}>
          <S.Card isFlipped={isFlipped}>
            <S.CardFront backgroundImg={paths[0]} />
            <S.CardBack backgroundImg={paths[1]} />
          </S.Card>
        </div>
        <S.DetailBox>
          <S.DetailContent>
            <CheapfakeImg name="lightbulb" type="svg" />
            <span>
              SafeClaim은 육안으로 식별하기 어려운{"\n"} 미세한 조작도 탐지할 수
              있어요
            </span>
          </S.DetailContent>
        </S.DetailBox>
      </S.BottomBox>
    </S.Container>
  );
};

export default Preview;
