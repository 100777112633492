import React from "react";
import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { S } from "../Styles/titlestyle";
import CheapfakeImg from "../../../Common/CheapfakeImg/CheapfakeImg";

const TiTleBox = () => {
  return (
    <S.Container>
      <S.TxtBox>
        <p className="BigTxt">
          SafeClaim의 AI 기술로
          <br />
          보험청구서를 철저히 검증해요
        </p>
        <p className="SmallTxt">
          SafeClaim은 다양한 AI 기술을 통합적으로 활용해 보험청구서의 진위
          여부를 파악할 수 있어요. 자체 개발한 AI 모델로 첨부 이미지의 조작
          여부를 판별하고, 고도화된 AI 모델을 통해 이미지와 텍스트 사이의 맥락적
          일치 여부까지 똑똑하게 검토해요.
        </p>
      </S.TxtBox>
      <S.ImgBox>
        <CheapfakeImg name="tech1" type="png" />
      </S.ImgBox>
    </S.Container>
  );
};

export default TiTleBox;
