import React, { useEffect, useState } from "react";

import MobileHeader from "./Common/MobileHeader";
import Banner from "./Component/Banner";
import Strength from "./Component/Strength";
import Preview from "./Component/Preview";
import Technology from "./Component/Technology";
import Notice from "./Component/Notice";
import UpFloatingBtn from "./Common/UpFloatingBtn";
import Question from "./Component/Question";
import MobileFooter from "./Common/MobileFooter";
import LinkCopyModal from "./Common/LinkCopyModal";

const MobilePage = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  // 클립 보드에 링크 복사
  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.log(err);
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000); // 3초 후에 isCopied를 false로 설정
  };
  //화면 켜지면 맨 상단으로 스크롤
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MobileHeader />
      <UpFloatingBtn />
      <Banner handleCopy={handleCopy} />
      <Strength handleCopy={handleCopy} />
      <Preview />
      <Technology />
      <Question />
      <Notice handleCopy={handleCopy} />
      <MobileFooter />
      {isCopied && <LinkCopyModal />}
    </>
  );
};

export default MobilePage;
