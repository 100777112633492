import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { UseFormReturn } from "react-hook-form";

import CarClaimForm from "./ClaimForm/CarClaimForm";
import DiagnosisClaimForm from "./ClaimForm/DiagnosisClaimForm";
import { S } from "./Styles/claimboxstyle";

import {
  CarForm,
  CarSegRes,
  DiagnosisForm,
  ForgeryStatRes,
  OCRRes,
} from "../../../../Utils/type";
import CheapfakeImg from "../../../../Common/CheapfakeImg/CheapfakeImg";
import ResultExplainModal from "./Modal/ResultExplainModal";
import { useDetectionState } from "../../../../GlobalState/useDetectionState";
import { Layer, Line, Stage } from "react-konva";

interface ClaimProps {
  setIsFilled: (value: boolean) => void;
  onSubmitHandlerDiagnosis: (value: DiagnosisForm) => void;
  onSubmitHandlerCar: (value: CarForm) => void;
  diagnosisFormMethods: UseFormReturn<DiagnosisForm>;
  carFormMethods: UseFormReturn<CarForm>;
  segResult: CarSegRes | undefined;
  forgeryResult: ForgeryStatRes | undefined;
}

const ClaimBox = ({
  setIsFilled,
  onSubmitHandlerDiagnosis,
  onSubmitHandlerCar,
  diagnosisFormMethods,
  carFormMethods,
  segResult,
  forgeryResult,
}: ClaimProps) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    // 화면 크기가 변경될 때마다 호출될 함수
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // 'resize' 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // 빈 배열을 주어 컴포넌트가 처음 렌더링될 때만 실행
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const imageRef = useRef<HTMLImageElement>(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const { file, isCarUploaded, isDiagnosisUploaded } = useDetectionState(
    (state) => state,
  );

  const fileUrl = useMemo(() => {
    if (file && file.size > 0) {
      return URL.createObjectURL(file);
    }
    return undefined;
  }, [file]);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  // 여기서 파일에 segResult 내부 mask_coord 값에 따라 영역 표시
  // 좌표 변환 함수 (모델 좌표를 브라우저의 이미지 좌표로 변환)
  const scaleCoordinates = useCallback(
    (points: number[]) => {
      if (
        imageSize.width === 0 ||
        imageSize.height === 0 ||
        containerSize.width === 0 ||
        containerSize.height === 0
      ) {
        return [];
      }
      //이미지 가로세로 비율
      const imageAspectRatio = imageSize.width / imageSize.height;
      //이미지가 들어가는 컨테이너의 가로세로 비율 (1:1)
      const containerAspectRatio = 1;

      //이미지 크기가 컨테이너 내부에서 어떻게 맞춰질지 계산
      let imageDisplayWidth: number, imageDisplayHeight: number;
      let offsetX = 0,
        offsetY = 0;
      //가로 길이가 더 길때
      if (imageAspectRatio > containerAspectRatio) {
        imageDisplayWidth = containerSize.width;
        imageDisplayHeight = containerSize.width / imageAspectRatio;
        offsetY = (containerSize.height - imageDisplayHeight) / 2;
      }
      //세로 길이가 더 길때
      else {
        imageDisplayHeight = containerSize.height;
        imageDisplayWidth = containerSize.height * imageAspectRatio;
        offsetX = (containerSize.width - imageDisplayWidth) / 2;
      }

      return points.map((value, index) => {
        // 가로
        if (index % 2 === 0) {
          return offsetX + (value / imageSize.width) * imageDisplayWidth;
        }
        // 세로
        else {
          return offsetY + (value / imageSize.height) * imageDisplayHeight;
        }
      });
    },
    [imageSize, containerSize],
  );

  // 좌표를 konva의 Line에 맞게 변환
  const polygons = useMemo(() => {
    if (
      !segResult ||
      !Array.isArray(segResult.results) ||
      containerSize.width === 0 ||
      containerSize.height === 0 ||
      imageSize.width === 0 ||
      imageSize.height === 0
    ) {
      return [];
    } else {
      return segResult.results.map(
        (result: Record<string, number | number[][]>) => {
          if (Array.isArray(result.mask_coord)) {
            return scaleCoordinates(result.mask_coord.flat());
          }
          return [];
        },
      );
    }
  }, [segResult, containerSize, imageSize, forgeryResult]);

  // Container와 konva stage 크기 맞춰주기
  useEffect(() => {
    if (imageRef.current) {
      const { offsetWidth, offsetHeight } = imageRef.current;
      setContainerSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [imageSize]);
  // 4:5 비율에 맞는 Container 크기 설정
  // useEffect(() => {
  //   if (windowWidth > 0) {
  //     const containerWidth = Math.min(windowWidth, 500);
  //     const containerHeight = containerWidth * (5 / 4);
  //     setContainerSize({ width: containerWidth, height: containerHeight });
  //   }
  // }, [windowWidth]);

  // 이미지 크기를 가져오는 함수
  useEffect(() => {
    if (!fileUrl) return;
    const img = new Image();
    img.src = fileUrl;

    //
    img.onload = () => {
      setImageSize({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
  }, [fileUrl, segResult]);

  useEffect(() => {
    console.log(forgeryResult);
  }, []);
  return (
    <S.Container>
      <S.ImageContainer>
        <S.UpperBox>
          <p>SafeClaim 이미지 검토 결과</p>
        </S.UpperBox>

        <S.ImageBox>
          <div className="Content">
            {forgeryResult &&
              forgeryResult.result &&
              typeof forgeryResult.result.model_output[0] !== "undefined" && (
                <>
                  {forgeryResult.result.model_output[0] === 0 && (
                    <S.TxtBox backgroundcolor="#19C654;">
                      <p>안전</p>
                    </S.TxtBox>
                  )}
                  {forgeryResult.result.model_output[0] === 1 && (
                    <S.TxtBox backgroundcolor="#fca907;">
                      <p>주의</p>
                    </S.TxtBox>
                  )}
                  {forgeryResult.result.model_output[0] === 2 && (
                    <S.TxtBox backgroundcolor="#FF5E5E;">
                      <p>위험</p>
                    </S.TxtBox>
                  )}
                </>
              )}
            <img src={fileUrl} className="File" ref={imageRef} />
            {/* Konva Stage: 이미지 위에 겹치는 캔버스 */}
            {polygons.length > 0 && isCarUploaded && (
              <S.KonvaContainer>
                <Stage
                  width={containerSize.width || 500}
                  height={containerSize.height || 500}
                >
                  <Layer>
                    {/* 좌표 기반 다각형 그리기 */}
                    {polygons.map((polygon, index) => (
                      <Line
                        key={index}
                        points={polygon}
                        closed
                        stroke="red"
                        strokeWidth={2}
                        fill="rgba(255, 232, 101, 0.3)"
                      />
                    ))}
                  </Layer>
                </Stage>
              </S.KonvaContainer>
            )}
          </div>
        </S.ImageBox>
        <S.BottomBox>
          {forgeryResult &&
            forgeryResult.result &&
            typeof forgeryResult.result.model_output[0] !== "undefined" && (
              <>
                {forgeryResult.result.model_output[0] === 0 && (
                  <>
                    <CheapfakeImg name="checkgreen" type="png" />
                    <span className="Text">위조 확률이 낮아요</span>
                  </>
                )}
                {forgeryResult.result.model_output[0] === 1 && (
                  <>
                    <CheapfakeImg name="alerttriangle" type="png" />
                    <span className="Text">위조 확률이 높아요</span>
                  </>
                )}
                {forgeryResult.result.model_output[0] === 2 && (
                  <>
                    <CheapfakeImg name="alertcircle" type="png" />
                    <span className="Text">위조 확률이 매우 높아요</span>
                  </>
                )}
              </>
            )}

          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setIsClicked((prev) => !prev)}
          >
            <span className="Plus">더보기</span>

            <CheapfakeImg name="rightchev" type="png" />
          </div>
          {isClicked && (
            <S.ModalPosition>
              <ResultExplainModal setIsClicked={setIsClicked} />
            </S.ModalPosition>
          )}
        </S.BottomBox>
      </S.ImageContainer>
      <S.FormContainer>
        {isCarUploaded && (
          <CarClaimForm
            file={fileUrl}
            setIsFilled={setIsFilled}
            onSubmitHandler={onSubmitHandlerCar}
            formMethods={carFormMethods}
          />
        )}
        {isDiagnosisUploaded && (
          <DiagnosisClaimForm
            file={fileUrl}
            setIsFilled={setIsFilled}
            onSubmitHandler={onSubmitHandlerDiagnosis}
            formMethods={diagnosisFormMethods}
          />
        )}
      </S.FormContainer>
    </S.Container>
  );
};

export default ClaimBox;
