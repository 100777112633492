import React from "react";
import styled from "styled-components";
import { colors } from "../../../../../Utils/colors";
import CheapfakeImg from "../../../../../Common/CheapfakeImg/CheapfakeImg";

type ModalProps = {
  setIsClicked: (value: boolean) => void;
};

const S = {
  ModalContainer: styled.div`
    width: 500px;
    box-sizing: border-box;
    padding: 16px 12px 16px 20px;
    position: relative;
    border-radius: 12px;
    border: 1px solid ${colors.primary3};
    background: ${colors.white};
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.08);
    z-index: 2;
  `,
  TextBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  `,
  Text: styled.span`
    color: ${colors[700]};

    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    letter-spacing: -0.16px;
  `,
  BoldText: styled.span`
    color: ${colors[700]};

    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    letter-spacing: -0.16px;
  `,
  CloseBtn: styled.div`
    position: absolute;
    top: 16px;
    right: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  `,
};

const TechExplainModal = ({ setIsClicked }: ModalProps) => {
  return (
    <S.ModalContainer>
      <S.TextBox>
        <div>
          <S.Text>- </S.Text>
          <S.BoldText>AI</S.BoldText>
          <S.Text>가</S.Text>
          <S.BoldText> 첨부 이미지</S.BoldText>
          <S.Text>와</S.Text>
          <S.BoldText> 클레임 텍스트</S.BoldText>
          <S.Text>간 맥락이 자연스러운지 파악해요</S.Text>
        </div>
        <div>
          <S.Text>
            - 텍스트 인식 기술(OCR)로 데이터 입력 과정을 자동화할 수 있어요
          </S.Text>
        </div>
        <div>
          <S.Text>
            - 특정 데이터를 지정할 수 있도록 커스텀 기능을 제공해요
          </S.Text>
        </div>
      </S.TextBox>
      <S.CloseBtn onClick={() => setIsClicked(false)}>
        <CheapfakeImg name="purple_x" type="png" />
      </S.CloseBtn>
    </S.ModalContainer>
  );
};

export default TechExplainModal;
