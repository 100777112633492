import CheapfakeImg from "../../Common/CheapfakeImg/CheapfakeImg";
import { S } from "./Style/mobileHeaderStyle";

const MobileHeader = () => {
  return (
    <S.Container>
      <S.Content>
        <CheapfakeImg name="safeclaim_icon" type="svg" />
        <S.Text>SafeClaim</S.Text>
      </S.Content>
    </S.Container>
  );
};

export default MobileHeader;
