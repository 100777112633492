import styled from "styled-components";
import { colors } from "../../../../../Utils/colors";

export const S = {
  UploadHead: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    align-self: stretch;

    & > span:nth-child(1) {
      color: ${colors[900]};

      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 24.2px */
      letter-spacing: -0.22px;
    }
    & > span:nth-child(2) {
      color: ${colors[700]};

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 16px */
      letter-spacing: -0.18px;
    }
  `,
  ItemContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    max-width: 100%;
    margin-top: -8px;
    & > p {
      color: ${colors[600]};
      text-align: center;

      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 20px */
      letter-spacing: -0.2px;
    }
  `,
  Item: styled.label`
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 20px;
    border: 1px dashed ${colors[500]};
    gap: 24px;
    height: 224px;
    cursor: pointer;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      & > p {
        color: ${colors[900]};
        text-align: center;

        font-size: 2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 20px */
        letter-spacing: -0.2px;
      }
    }
  `,
  ExampleBox: styled.div`
    display: grid;

    grid-template-columns: repeat(4, 1fr);
    width: 60%;
    gap: 10px;
    box-sizing: border-box;
  `,
  ExampleItem: styled.div`
    border: 0.5px solid ${colors.primary4};

    cursor: pointer;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 0;
    }
    & > input {
      display: none;
      width: 100%;
      pointer-events: none;
    }
  `,
  UploadBtn: styled.label`
    display: flex;
    cursor: pointer;
    height: 54px;
    padding: 12px 28px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 999px;
    background: ${colors[100]};
    & > img {
      width: 16px;
      height: 16px;
      color: ${colors[900]};
    }
    & > p {
      color: ${colors[900]};
      text-align: center;

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 18px */
      letter-spacing: -0.18px;
    }
    input {
      display: none;
    }
  `,
  TextBox: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    & > span {
      color: ${colors[700]};
      font-family: Pretendard;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 18px */
      letter-spacing: -0.36px;
      .Focus {
        cursor: pointer;
        font-weight: 700;
        text-decoration-line: underline;
      }
    }
  `,
};
