import { createGlobalStyle } from "styled-components";
import { colors } from "../Utils/colors";

//DatePicker 스타일
export const GlobalStyle = createGlobalStyle`
  //DatePicker 전체를 감싸는 wrapper요소
  .date-picker-wrapper {
    width: 100%;
    position: relative;
  }
  //DatePicker 팝업을 감싸는 요소
  .react-datepicker-popper {
    width: 100% !important; 
    left: auto !important;
    right: 0 !important; 
}

//달력 자체 요소
.react-datepicker {
    width: 100%; 
}
//달력 헤더 요소
.react-datepicker__header {
    background-color: ${colors[100]}; /* 원하는 배경 색상으로 변경 */
    border-bottom: 1px solid ${colors[200]}; /* 원하는 경계 색상으로 변경 */
}
//달력의 삼각형 요소
.react-datepicker__triangle {
    display: none; /* 삼각형 제거 */
}
//Datepicker의 월을 감싸는 요소
.react-datepicker__month-container {
    width: 100%; /* 달력의 너비를 전체로 설정 */
}

//일, 요일이름, 현재 월 요소
.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__current-month {
    font-size: 1.8rem; /* 글꼴 크기 조정 */
}
//선택된 일, 선택된 범위 내의 일
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    background-color: ${colors[500]};
    color: white;
}
//요일 이름, 일 시간이름 나타내는 요소
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 12%; 
    text-align: center; /* 가운데 정렬 */
}

`;
