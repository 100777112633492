import React from "react";
import styled from "styled-components";
import { colors } from "../Utils/colors";
import useContact from "../GlobalState/useContact";
import CheapfakeImg from "./CheapfakeImg/CheapfakeImg";

const S = {
  Container: styled.div`
    display: flex;
    width: 92px;
    height: 92px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 56px;
    background: ${colors.primary};
    box-shadow: 8px 8px 30px 0px rgba(150, 160, 255, 0.7);
    box-sizing: border-box;
    position: fixed;
    right: 25px;
    bottom: 25px;
    cursor: pointer;
    & > img {
      width: 56px;
      height: 56px;
      flex-shrink: 0;
    }
    &:hover {
      background: ${colors.primary5};
    }
  `,
};

const FloatingBtn = () => {
  const { isContact, setIsContact } = useContact();
  return (
    <S.Container onClick={() => setIsContact(true)}>
      <CheapfakeImg name="chat-alt" type="png" />
    </S.Container>
  );
};

export default FloatingBtn;
