import { create } from "zustand";
import { persist } from "zustand/middleware";

interface DetectionState {
  currentStage: number;
  isCarUploaded: boolean;
  isDiagnosisUploaded: boolean;
  file: File | null;
  setCurrentStage: (value: number) => void;
  setIsCarUploaded: (value: boolean) => void;
  setIsDiagnosisUploaded: (value: boolean) => void;
  setFile: (file: File | null) => void;
  resetState: () => void;
}

export const useDetectionState = create<DetectionState>()((set) => ({
  currentStage: 1,
  isCarUploaded: false,
  isDiagnosisUploaded: false,
  file: null,
  setCurrentStage: (stage: number) => set({ currentStage: stage }),
  setIsCarUploaded: (value: boolean) => set({ isCarUploaded: value }),
  setIsDiagnosisUploaded: (value: boolean) =>
    set({ isDiagnosisUploaded: value }),
  setFile: (file: File | null) => set({ file: file }),
  resetState: () =>
    set({
      currentStage: 1,
      isCarUploaded: false,
      isDiagnosisUploaded: false,
      file: null,
    }),
}));
