import React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../Utils/colors";
import CheapfakeImg from "../../Common/CheapfakeImg/CheapfakeImg";

const S = {
  Button: styled.div`
    display: flex;
    width: 44px;
    height: 44px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    position: fixed;
    cursor: pointer;
    right: 15px;
    bottom: 14px;
    border-radius: 999px;
    border: 1px solid ${colors[300]};
    background: ${colors.white};
    box-shadow: 0px 3px 8px 0px #e0e3ff;
    box-sizing: border-box;
    z-index: 10;
    & > img {
      height: 16px;
      flex-shrink: 0;
    }
  `,
};
const UpFloatingBtn = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <S.Button onClick={handleClick}>
      <CheapfakeImg name="arrowup" type="svg" />
    </S.Button>
  );
};

export default UpFloatingBtn;
