import React, { useCallback, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { useNavigate } from "react-router-dom";

interface CardProps {
  path: string;
  index: number;
}

const LS = {
  Card: styled.div<{ isFlipped: boolean }>`
    width: calc(281 / 1280 * 100vw);
    max-width: 351.25px; /*  281/1280*1600  */
    height: calc(368 / 1280 * 100vw);
    max-height: 460px; /*  368/1280*1600  */
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    transform: ${({ isFlipped }) =>
      isFlipped ? "rotateY(180deg)" : "rotateY(0)"};
    cursor: pointer;
    @media ${(props) => props.theme.device.tablet} {
      width: 100vw;
      height: calc((368 / 281) * 100vw);
    }
  `,
  CardFront: styled.div<{ backgroundImg: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-image: url(${(props) => props.backgroundImg});
    background-size: cover;
    background-position: center;
    border-radius: 16px;
    border: 1px solid ${colors[300]};
    &:hover {
      background-image: url(${(props) =>
        props.backgroundImg.replace("default", "hover")});
    }
  `,
  CardBack: styled.div<{ backgroundImg: string; index: number }>`
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-image: url(${(props) => props.backgroundImg});
    background-size: cover;
    background-position: center;
    border-radius: 16px;
    transform: rotateY(180deg);
    border: 4px solid
      ${(props) =>
        props.index === 0 || props.index === 3 ? "#33D96B" : "#ff6666"};
  `,
};

const S = {
  Container: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    @media ${(props) => props.theme.device.tablet} {
      grid-template-columns: repeat(2, 1fr);
    }
  `,
};

const TryCard = ({ path, index }: CardProps) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();
  const handleCardClick = () => {
    if (!isFlipped) setIsFlipped(!isFlipped);
    else navigate("/detection");
  };
  //호버 시 이미지를 미리 로드하여 처음 접속 시 이미지 호출하면서 깜빡거리는 현상 해결
  const hoverImgPreload = useCallback(() => {
    const paths = [
      process.env.REACT_APP_PUBLIC_URL + "/icon/hover1.png",
      process.env.REACT_APP_PUBLIC_URL + "/icon/hover2.png",
      process.env.REACT_APP_PUBLIC_URL + "/icon/hover3.png",
      process.env.REACT_APP_PUBLIC_URL + "/icon/hover4.png",
    ];

    paths.map((path, index) => {
      let img = new Image();
      img.src = path;
    });
  }, []);
  //화면 랜더 이후에 동작하는 useEffect와는 달리 useLayoutEffect는 랜더링 이전에 동작하는 Hook
  useLayoutEffect(() => {
    hoverImgPreload();
  }, []);

  return (
    <div onClick={handleCardClick}>
      <LS.Card isFlipped={isFlipped}>
        <LS.CardFront backgroundImg={path} />
        <LS.CardBack
          backgroundImg={path.replace("default", "selected")}
          index={index}
        />
      </LS.Card>
    </div>
  );
};

const TryCards = () => {
  const paths = [
    process.env.REACT_APP_PUBLIC_URL + "/icon/default1.png",
    process.env.REACT_APP_PUBLIC_URL + "/icon/default2.png",
    process.env.REACT_APP_PUBLIC_URL + "/icon/default3.png",
    process.env.REACT_APP_PUBLIC_URL + "/icon/default4.png",
  ];

  return (
    <S.Container>
      {paths.map((item, index) => (
        <TryCard key={index} path={item} index={index} />
      ))}
    </S.Container>
  );
};

export default TryCards;
