import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 36px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    background: ${colors[200]};
  `,
  ContentBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  `,
  InfoBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    & > span {
      color: var(--700, #56596f);

      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      letter-spacing: -0.24px;
    }
    & > span:first-child {
      font-weight: 700;
    }
  `,
  SubBox: styled.div`
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-top: 0.6px solid ${colors[400]};
  `,
  SubContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    .SubText {
      color: ${colors[700]};

      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 15.4px */
      letter-spacing: -0.22px;
    }
    & > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  `,
};
