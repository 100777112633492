import styled from "styled-components";
import { colors } from "../../../../../../Utils/colors";

export const S = {
  ClaimBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    gap: 20px;
    align-self: stretch;
    background-color: ${colors.white};
    border-radius: 0px 0px 16px 16px;
  `,
  Title: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    & > span {
      color: ${colors[900]};

      font-size: 2.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 24.2px */
      letter-spacing: -0.22px;
    }
  `,
  ModalPosition: styled.div`
    position: absolute;
    z-index: 2;
    bottom: 40px;
  `,

  CarImgBox: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;
    .car-image-container {
      position: relative;
      & > img {
        width: 392px;
        height: 220px;
      }
    }
    .car-overlay-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      z-index: 2;
      mix-blend-mode: multiply;
    }
  `,
  CarForm: styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
  `,
  Item: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    gap: 8px;
    position: relative;

    .FormInput {
      display: flex;
      padding: 12px;
      border-radius: 6px;
      border: none;
      background-color: ${colors[100]};
      align-self: stretch;
      color: ${colors[700]};

      font-size: 1.7rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 23.4px */
      letter-spacing: -0.3px;
      &::placeholder {
        color: ${colors[500]};
      }
    }
    .FormLabel {
      padding: 0 4px;
      color: ${colors[900]};

      font-size: 1.7rem;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 23.4px */
      letter-spacing: -0.3px;
      & > span {
        margin-left: 2px;
        color: ${colors.primary};

        font-size: 1.7rem;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 18px */
        letter-spacing: -0.3px;
      }
    }
    .FormCheckBox {
      margin-top: 12px;
      display: grid;
      padding: 0px 4px;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 12px;
      width: 100%;
      align-self: stretch;
      & > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 16px;
        text-align: left;
        & > label {
          display: flex;
          align-items: center;
          gap: 8px;
          /* 텍스트 */
          color: ${colors[700]};

          font-size: 1.7rem;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 18px */
          letter-spacing: -0.3px;
        }
      }
    }
  `,
  CheckBox: styled.div`
    display: flex;
    width: 24px; /* 이미지에 맞춰 크기 조절 */
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: relative;
    background: ${colors[100]}; /* 체크되지 않았을 때 배경색 */

    & > input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    & > img {
      width: 100%;
      height: 100%;
    }

    & > input:checked + img {
      border-radius: 4px;
      content: url("/icon/yescheck.png"); // 체크되었을 때 이미지 변경
      background-color: ${colors.primary}; //체크 되었을 때 배경색
    }
  `,
  SelectBox: styled.ul`
    display: flex;
    padding: 8px 0px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid ${colors[700]};
    background-color: ${colors[100]};
    position: absolute;
    top: 90px;
    left: 0;
    z-index: 1;
    & > li {
      display: flex;
      padding: 12px;
      align-items: center;
      align-self: stretch;
      background-color: ${colors[100]};
      color: ${colors[700]};
      text-align: center;

      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 136%; /* 24.48px */
      letter-spacing: -0.18px;
      &:hover {
        background: ${colors[200]};
      }
    }
  `,
};
