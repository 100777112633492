import styled from "styled-components";
import { colors } from "../../Utils/colors";
import { S } from "./footerstyle";
import useContact from "../../GlobalState/useContact";
import { useNavigate } from "react-router-dom";
import CheapfakeImg from "../CheapfakeImg/CheapfakeImg";

const Footer = () => {
  const { isContact, setIsContact } = useContact();
  const navigate = useNavigate();
  return (
    <S.BackgroundContainer>
      <S.FooterContainer>
        <S.FooterTab>
          <S.LogoBox>
            <CheapfakeImg name="SafeAILogo" type="png" />
          </S.LogoBox>
          <S.ItemBox>
            <S.Item onClick={() => navigate("/")}>
              <p>홈</p>
            </S.Item>
            <S.Item onClick={() => navigate("/detection")}>
              <p>서비스 체험</p>
            </S.Item>
            <S.Item onClick={() => navigate("/why")}>
              <p>SafeClaim 소개</p>
            </S.Item>
            <S.Item onClick={() => navigate("/technology")}>
              <p>AI 기술</p>
            </S.Item>
            <S.Item onClick={() => setIsContact(true)}>
              <p>문의하기</p>
            </S.Item>
          </S.ItemBox>
        </S.FooterTab>
        <S.InfoBox>
          <S.Info>
            <div className="InfoItem">
              <p>(주)세이프에이아이</p>
              <p className="Both">대표: 이원섭</p>
              <p>서울시 강남구 영동대로 324 804호</p>
            </div>
            <div className="InfoItem">
              <p className="Right">
                대전 지사: 대전시 유성구 테크노4로 17 A418
              </p>
              <p>
                유럽 지사: 11-13 Bd Grande-Duchesse Charlotte, L-1331 Luxembourg
              </p>
            </div>
            <div className="InfoItem">
              <p className="Right">contact@safeai.kr</p>
              <p>개인정보관리책임자: 김원석</p>
            </div>
          </S.Info>
          <S.Footer>
            <p
              className="Link"
              onClick={() =>
                window.open(
                  "https://safe-ai.notion.site/106f5deaf68a80f2a541e24edfeb11a9?pvs=4",
                )
              }
            >
              개인정보처리방침
            </p>
            <p
              className="Link"
              onClick={() =>
                window.open(
                  "https://safe-ai.notion.site/106f5deaf68a802f8d22cf40a067810a?pvs=4",
                )
              }
            >
              서비스 이용약관
            </p>
            <p>Copyright © 2024 SafeAI. All Rights Reserved</p>
          </S.Footer>
        </S.InfoBox>
      </S.FooterContainer>
    </S.BackgroundContainer>
  );
};

export default Footer;
