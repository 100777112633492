import React from "react";
import styled from "styled-components";
import { colors } from "../../Utils/colors";
import CheapfakeImg from "../../Common/CheapfakeImg/CheapfakeImg";
const S = {
  Container: styled.div`
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    //화면에서 가운데 위치
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    background: ${colors[900]};
    white-space: nowrap;
    & > span {
      color: ${colors[200]};

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 16px */
      letter-spacing: -0.32px;
    }
  `,
};
const LinkCopyModal = () => {
  return (
    <S.Container>
      <CheapfakeImg name="check-circlegreen" type="svg" />
      <span>링크가 복사되었습니다.</span>
    </S.Container>
  );
};

export default LinkCopyModal;
