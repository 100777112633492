import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 40px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;
    align-self: stretch;
    background: ${colors[100]};
    box-sizing: border-box;
  `,
  UpperBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    //텍스트 스타일

    font-size: 2.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.884px;
    & > img {
      object-fit: cover;
      width: 60%;
    }
    .Normal {
      color: ${colors[900]};
      white-space: pre-line;
    }
    .Purple {
      color: ${colors.primary};
      white-space: pre-line;
    }
  `,
  LowerBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    & > span {
      color: ${colors[700]};
      text-align: center;

      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: -0.28px;
    }
  `,
};
