const deviceSizes = {
  mobile: 767,
  tablet: 1279,
  laptop: 1280,
};

const device = {
  mobile: `screen and (max-width: ${deviceSizes.mobile}px)`,
  tablet: `screen and (max-width: ${deviceSizes.tablet}px)`,
  laptop: `screen and (min-width: ${deviceSizes.laptop}px)`,
};

const theme = {
  device,
};

export default theme;
