import axios from "axios";

type useBucketUploadReturn = {
  uploadImgToBucket: (file: File | null) => Promise<string | null>;
};

const useBucketUpload = (): useBucketUploadReturn => {
  const uploadImgToBucket = async (
    file: File | null,
  ): Promise<string | null> => {
    if (!file) return null; // 파일이 없을 때 null 반환
    try {
      const timestamp_date =
        new Date()
          .toLocaleString("sv-SE", { timeZone: "Asia/Seoul" })
          .replace(/[-: ]/g, "")
          .split(".")[0] +
        new Date().getMilliseconds().toString().padStart(3, "0");
      const fileName = `test_data/car_seg_test/${file.name.split(".")[0]}-${timestamp_date}.png`;
      const blob = new Blob([file], { type: file.type });

      const response = await axios.post(
        `https://storage.googleapis.com/upload/storage/v1/b/ml-tracking-server/o?uploadType=media&name=${fileName}`,
        blob,
        {
          headers: {
            "Content-Type": "image/png",
          },
        },
      );

      console.log("업로드 성공: ", response);
      return fileName;
    } catch (error) {
      console.error("업로드 실패: ", error);
      return null; // 에러가 발생하면 null 반환
    }
  };
  return { uploadImgToBucket };
};

export default useBucketUpload;
