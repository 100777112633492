import styled, { keyframes } from "styled-components";
import { colors } from "../../../Utils/colors";

const floating = keyframes`
0% {
  transform: translateY(0%);
}
50% {
  transform: translateY(10%);
}
100% {
  transform: translateY(0%);
}
`;
export const S = {
  Container: styled.div`
    display: flex;
    padding: 44px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background: ${colors.bg2};
    box-sizing: border-box;
  `,
  TextBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    .SubjectBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 14px;
      & > span:nth-child(1) {
        color: ${colors.primary};
        text-align: center;

        font-size: 1.3rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 19.5px */
        letter-spacing: -0.13px;
        margin-bottom: 4px;
      }
      & > span:nth-child(2) {
        color: ${colors[900]};
        text-align: center;

        font-size: 2.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 33px */
        letter-spacing: -0.66px;
      }
    }
  `,
  BottomBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  `,
  TextBalloon: styled.div`
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: ${colors[900]};
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    animation-name: ${floating};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    margin-bottom: 12px;
    & > span {
      color: ${colors.white};

      font-size: 1.3rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 13px */
      letter-spacing: -0.26px;
    }
    //아래 꼭지
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 10px solid ${colors[900]};
      border-bottom: 10px solid transparent;
      transform: translate(-50%, 0);
    }
  `,
  DetailBox: styled.div`
    display: flex;
    padding: 16px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid ${colors.primary4};
    background: ${colors.white};
  `,
  DetailContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    white-space: pre-line;
    & > span {
      color: ${colors[900]};
      text-align: center;

      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 21px */
      letter-spacing: -0.14px;
    }
    & > img {
      object-fit: cover;
      width: 18px;
    }
  `,
  Card: styled.div<{ isFlipped: boolean }>`
    width: calc(281 / 1280 * 100vw);
    max-width: 351.25px; /*  281/1280*1600  */
    height: calc(368 / 1280 * 100vw); /*  368/1280*1600  */
    max-height: 460px;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
    transform: ${({ isFlipped }) =>
      isFlipped ? "rotateY(180deg)" : "rotateY(0)"};
    cursor: pointer;
    @media ${(props) => props.theme.device.tablet} {
      width: 100vw;
      height: calc((368 / 281) * 100vw);
    }
  `,
  CardFront: styled.div<{ backgroundImg: string }>`
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-image: url(${(props) => props.backgroundImg});
    background-size: cover;
    background-position: center;
    border-radius: 16px;
    border: 1px solid ${colors[300]};
  `,
  CardBack: styled.div<{ backgroundImg: string }>`
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-image: url(${(props) => props.backgroundImg});
    background-size: cover;
    background-position: center;
    border-radius: 16px;
    transform: rotateY(180deg);
  `,
};
