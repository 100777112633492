import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 44px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    box-sizing: border-box;
  `,
  TextBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    & > span:nth-child(1) {
      color: ${colors.primary};
      text-align: center;

      font-size: 1.3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 19.5px */
      letter-spacing: -0.13px;
    }
    & > span:nth-child(2) {
      white-space: pre-line;
      color: ${colors[900]};
      text-align: center;

      font-size: 2.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 33px */
      letter-spacing: -0.66px;
    }
  `,
  ContentBox: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    & > img {
      object-fit: cover;
      width: 100%;
    }
  `,
  InfoBox: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      .Star {
        color: ${colors.primary};
        font-weight: 700;
      }
      & > span:nth-child(1) {
        color: ${colors[900]};
        text-align: center;

        font-size: 1.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: 146%; /* 24.82px */
        letter-spacing: -0.51px;
      }
      & > span:nth-child(2) {
        color: var(--900, #26262c);
        text-align: center;

        font-size: 1.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 25.5px */
        letter-spacing: -0.34px;
        white-space: pre-line;
      }
    }
    & > span {
      color: var(--600, #9195ab);
      text-align: center;

      font-size: 1.3rem;
      font-style: normal;
      font-weight: 500;
      line-height: 156%; /* 20.28px */
      letter-spacing: -0.26px;
      white-space: pre-line;
    }
  `,
};
