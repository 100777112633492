import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { S } from "./Styles/resultboxstyle";

import CheapfakeImg from "../../../../Common/CheapfakeImg/CheapfakeImg";
import { useDetectionState } from "../../../../GlobalState/useDetectionState";
import { Layer, Line, Stage } from "react-konva";
import { CarSegRes } from "../../../../Utils/type";

interface ResultProps {
  setIsDetected: (value: boolean) => void;
  segResult: CarSegRes | undefined;
  finalResult: number | undefined;
  isCarUploaded: boolean;
}

const ResultBox = ({
  setIsDetected,
  segResult,
  finalResult,
  isCarUploaded,
}: ResultProps) => {
  const {
    currentStage,
    setCurrentStage,
    setIsCarUploaded,
    setIsDiagnosisUploaded,
    file,
  } = useDetectionState((state) => state);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const imageRef = useRef<HTMLImageElement>(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const fileUrl = useMemo(
    () => (file ? URL.createObjectURL(file) : undefined),
    [file],
  );
  const handleReloadClick = () => {
    setIsDiagnosisUploaded(false);
    setIsCarUploaded(false);
    setIsDetected(false);
    setCurrentStage(1);
  };

  //차량 분할 모델 결과 화면에 나타내기 위한 로직
  //좌표 변환 함수 (모델 좌표를 브라우저의 이미지 좌표로 변환)
  const scaleCoordinates = useCallback(
    (points: number[]) => {
      if (
        imageSize.width === 0 ||
        imageSize.height === 0 ||
        containerSize.width === 0 ||
        containerSize.height === 0
      ) {
        return [];
      }
      //이미지 가로세로 비율
      const imageAspectRatio = imageSize.width / imageSize.height;
      //이미지가 들어가는 컨테이너의 가로세로 비율 (1:1)
      const containerAspectRatio = containerSize.width / containerSize.height;

      //이미지 크기가 컨테이너 내부에서 어떻게 맞춰질지 계산
      let imageDisplayWidth: number, imageDisplayHeight: number;
      let offsetX = 0,
        offsetY = 0;
      //가로 길이가 더 길때
      if (imageAspectRatio > containerAspectRatio) {
        imageDisplayWidth = containerSize.width;
        imageDisplayHeight = containerSize.width / imageAspectRatio;
        offsetY = (containerSize.height - imageDisplayHeight) / 2;
      }
      //세로 길이가 더 길때
      else {
        imageDisplayHeight = containerSize.height;
        imageDisplayWidth = containerSize.height * imageAspectRatio;
        offsetX = (containerSize.width - imageDisplayWidth) / 2;
      }

      return points.map((value, index) => {
        // 가로
        if (index % 2 === 0) {
          return offsetX + (value / imageSize.width) * imageDisplayWidth;
        }
        // 세로
        else {
          return offsetY + (value / imageSize.height) * imageDisplayHeight;
        }
      });
    },
    [imageSize, containerSize],
  );

  // 좌표를 konva의 Line에 맞게 변환
  const polygons = useMemo(() => {
    if (
      !segResult ||
      !Array.isArray(segResult.results) ||
      containerSize.width === 0 ||
      containerSize.height === 0 ||
      imageSize.width === 0 ||
      imageSize.height === 0
    ) {
      return undefined;
    } else {
      return segResult.results.map(
        (result: Record<string, number | number[][]>) => {
          if (Array.isArray(result.mask_coord)) {
            return scaleCoordinates(result.mask_coord.flat());
          }
          return [];
        },
      );
    }
  }, [segResult, containerSize, imageSize]);

  //Container와 konva stage 크기 맞춰주기
  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      const { offsetWidth, offsetHeight } = imageRef.current;
      setContainerSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [fileUrl, imageSize]);

  // useEffect(() => {
  //   console.log(polygons);
  // }, [polygons]);
  // 이미지 크기를 가져오는 함수
  useEffect(() => {
    if (!fileUrl) return;
    const img = new Image();
    img.src = fileUrl;

    img.onload = () => {
      setImageSize({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
  }, [fileUrl, segResult]);
  useEffect(() => {
    console.log(finalResult);
  }, [finalResult]);
  return (
    <S.Container>
      <S.ImgBox>
        <img src={fileUrl} alt="image" ref={imageRef} />
        <S.KonvaContainer>
          {containerSize.width > 0 &&
            containerSize.height > 0 &&
            isCarUploaded && (
              <Stage width={containerSize.width} height={containerSize.height}>
                <Layer>
                  {polygons &&
                    polygons.map((polygon, index) => (
                      <Line
                        key={index}
                        points={polygon}
                        closed
                        stroke="red"
                        strokeWidth={2}
                        fill="rgba(255, 232, 101, 0.3)"
                      />
                    ))}
                </Layer>
              </Stage>
            )}
        </S.KonvaContainer>
      </S.ImgBox>
      <S.ContentBox>
        {typeof finalResult !== "undefined" && finalResult !== null && (
          <>
            {finalResult === 0 && (
              <>
                <CheapfakeImg name="shield-check" type="png" />
                <p>안전해요</p>
                <S.ResultTxt>
                  <div className="Txt">
                    <CheapfakeImg name="resultcheck" type="png" />
                    <span>이미지</span>
                    <span className="Bold">위조 확률이 낮아요</span>
                  </div>
                  <div className="Txt">
                    <CheapfakeImg name="resultcheck" type="png" />
                    <span>이미지와 클레임 내용 간</span>
                    <span className="Bold">맥락이 자연스러워요</span>
                  </div>
                </S.ResultTxt>
              </>
            )}
            {finalResult === 1 && (
              <>
                <CheapfakeImg name="shield-yellow" type="png" />
                <p>주의하세요</p>
                <S.ResultTxt>
                  <div className="Txt">
                    <CheapfakeImg name="resultcheck" type="png" />
                    <span>이미지</span>
                    <span className="Bold">위조 확률이 높아요</span>
                  </div>
                  <div className="Txt">
                    <CheapfakeImg name="resultcheck" type="png" />
                    <span>이미지와 클레임 내용 간</span>
                    <span className="Bold">맥락이 부자연스러워요</span>
                  </div>
                </S.ResultTxt>
              </>
            )}
            {finalResult === 2 && (
              <>
                <CheapfakeImg name="shield-red" type="png" />
                <p>검토가 필요해요</p>
                <S.ResultTxt>
                  <div className="Txt">
                    <CheapfakeImg name="resultcheck" type="png" />
                    <span>이미지</span>
                    <span className="Bold">위조 확률이 높아요</span>
                  </div>
                  <div className="Txt">
                    <CheapfakeImg name="resultcheck" type="png" />
                    <span>이미지와 클레임 내용 간</span>
                    <span className="Bold">맥락이 부자연스러워요</span>
                  </div>
                </S.ResultTxt>
              </>
            )}
          </>
        )}

        <S.RetryBtn onClick={handleReloadClick}>다시하기</S.RetryBtn>
      </S.ContentBox>
    </S.Container>
  );
};

export default ResultBox;
