import React, { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Common/Header/Header";
import Footer from "./Common/Footer/Footer";
import FloatingBtn from "./Common/FloatingBtn";
import ContactModal from "./Common/Modal/ContactModal";
import useContact from "./GlobalState/useContact";
import useMenu from "./GlobalState/useMenu";
import DrawerMenu from "./Common/Modal/DrawerMenu";

const Main = () => {
  const { isContact, setIsContact } = useContact();
  const { isMenu, setIsMenu } = useMenu();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: 767px)`);

    const handleChange = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addEventListener("change", handleChange);

    // 초기값 설정
    setIsMobile(mediaQuery.matches);

    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);
  return (
    <>
      {isContact && <ContactModal />}
      {isMenu && <DrawerMenu />}
      {!isMobile && (
        <Header setIsContact={setIsContact} setIsMenu={setIsMenu} />
      )}
      <Outlet />
      {!isMobile && <FloatingBtn />}

      {!isMobile && <Footer />}
    </>
  );
};

export default Main;
