import React from "react";
import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { S } from "../\bStyles/strengthstyle";

const ThreeStrength = () => {
  return (
    <S.Container>
      <S.Item>
        <S.ImgBox
          backgroundImage={
            process.env.REACT_APP_PUBLIC_URL + "/icon/intro4.png"
          }
        />
        <S.TxtBox>
          <div className="TxtFocus">
            <p className="Green">쉬운 서비스 이용</p>
            <p>
              이미지와 텍스트 데이터만으로
              <br />
              위조 가능성 체크
            </p>
          </div>
        </S.TxtBox>
      </S.Item>
      <S.Item>
        <S.TxtBox>
          <div className="TxtFocus">
            <p className="Blue">안전한 고객 정보 관리</p>
            <p>
              고객 데이터 보안을 위한
              <br />
              온프레미스* 운영
            </p>
          </div>
          <p className="Detail">
            *온프레미스(On-premises): 서버와 인프라를 소유 & 관리하는 방식
          </p>
        </S.TxtBox>
        <S.ImgBox
          backgroundImage={
            process.env.REACT_APP_PUBLIC_URL + "/icon/intro5.png"
          }
        />
      </S.Item>
      <S.Item>
        <S.ImgBox
          backgroundImage={
            process.env.REACT_APP_PUBLIC_URL + "/icon/intro6.png"
          }
        />
        <S.TxtBox>
          <div className="TxtFocus">
            <p className="Purple">신속한 탐지</p>
            <p>
              청구 문서 위조 가능성
              <br />
              2초 만에 확인
            </p>
          </div>
          <p className="Detail">*공인기관 성능 평가 인증</p>
        </S.TxtBox>
      </S.Item>
    </S.Container>
  );
};

export default ThreeStrength;
