import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    height: 60px;
    padding: 4px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    box-sizing: border-box;
  `,
  Content: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
  `,
  Text: styled.span`
    color: ${colors[900]};

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 18px */
  `,
};
