import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { S } from "./Styles/carformstyle";
import { UseFormReturn } from "react-hook-form";
import { CarForm } from "../../../../../Utils/type";
import CheapfakeImg from "../../../../../Common/CheapfakeImg/CheapfakeImg";
import TechExplainModal from "../Modal/TechExplainModal";

interface Props {
  file: string | undefined;
  setIsFilled: (value: boolean) => void;
  onSubmitHandler: (data: CarForm) => void;
  formMethods: UseFormReturn<CarForm>;
}

const CarClaimForm = ({
  file,
  setIsFilled,
  onSubmitHandler,
  formMethods,
}: Props) => {
  const [selectedParts, setSelectedParts] = useState<string[]>([]);
  const { register, watch, setValue, handleSubmit } = formMethods;
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const partImageURL: { [key: string]: string } = {
    front: "/1_front.png", // Your overlay image paths
    back: "/2_back.png",
    side: "/3_side.png",
    upper: "/4_top.png",
    otherPart: "/5_etc.png",
  };

  const handlePartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedParts((prev) => [...prev, value]);
    } else {
      setSelectedParts((prev) => prev.filter((part) => part !== value));
    }
  };

  // 폼의 모든 내용 채워졌나 확인
  useEffect(() => {
    const subscription = watch((value) => {
      const { part, type, content } = value;
      const allFieldsFilled = part && type && content;
      setIsFilled(Boolean(allFieldsFilled));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setValue("file", file);
  }, [file]);

  return (
    <S.ClaimBox>
      <S.Title>
        <span>클레임 내용 입력</span>
        <div
          onClick={() => setIsClicked((prev) => !prev)}
          style={{ cursor: "pointer" }}
        >
          <CheapfakeImg name="alert-circle" type="svg" />
        </div>
        {isClicked && (
          <S.ModalPosition>
            <TechExplainModal setIsClicked={setIsClicked} />
          </S.ModalPosition>
        )}
      </S.Title>

      <S.CarImgBox>
        <div className="car-image-container">
          <CheapfakeImg name="default" type="png" />
          {selectedParts.map((part) => (
            <img
              key={part}
              src={
                process.env.REACT_APP_PUBLIC_URL + "/icon" + partImageURL[part]
              }
              className="car-overlay-image"
              alt={`${part} overlay`}
            />
          ))}
        </div>
      </S.CarImgBox>

      <S.CarForm onSubmit={handleSubmit(onSubmitHandler)}>
        <S.Item>
          <label className="FormLabel">
            사고 부위<span>*</span>
          </label>
          <div className="FormCheckBox">
            <div>
              <label htmlFor="front">
                <S.CheckBox>
                  <input
                    type="checkbox"
                    {...register("part", { required: true })}
                    value="front"
                    id="front"
                    onChange={handlePartChange}
                  />
                  <CheapfakeImg name="nocheck" type="png" />
                </S.CheckBox>
                앞 - 본네트, 앞범퍼, 앞유리 등
              </label>
              <label htmlFor="back">
                <S.CheckBox>
                  <input
                    type="checkbox"
                    {...register("part", { required: true })}
                    value="back"
                    id="back"
                    onChange={handlePartChange}
                  />
                  <CheapfakeImg name="nocheck" type="png" />
                </S.CheckBox>
                뒤 - 트렁크, 뒷범퍼, 뒷유리 등
              </label>
            </div>
            <div>
              <label htmlFor="side">
                <S.CheckBox>
                  <input
                    type="checkbox"
                    {...register("part", { required: true })}
                    value="side"
                    id="side"
                    onChange={handlePartChange}
                  />
                  <CheapfakeImg name="nocheck" type="png" />
                </S.CheckBox>
                옆 - 문, 사이드미러, 옆유리 등
              </label>
              <label htmlFor="upper">
                <S.CheckBox>
                  <input
                    type="checkbox"
                    {...register("part", { required: true })}
                    value="upper"
                    id="upper"
                    onChange={handlePartChange}
                  />
                  <CheapfakeImg name="nocheck" type="png" />
                </S.CheckBox>
                위 - 루프, 선루프 등
              </label>
            </div>
            <div>
              <label htmlFor="otherPart">
                <S.CheckBox>
                  <input
                    type="checkbox"
                    {...register("part", { required: true })}
                    value="otherPart"
                    id="otherPart"
                    onChange={handlePartChange}
                  />
                  <CheapfakeImg name="nocheck" type="png" />
                </S.CheckBox>
                기타 - 타이어, 휠 등
              </label>
            </div>
          </div>
        </S.Item>
        <S.Item>
          <label className="FormLabel">
            손상 종류<span>*</span>
          </label>
          <div className="FormCheckBox">
            <div>
              <label htmlFor="scratch">
                <S.CheckBox>
                  <input
                    type="checkbox"
                    id="scratch"
                    {...register("type", { required: true })}
                    value="scratch"
                  />{" "}
                  <CheapfakeImg name="nocheck" type="png" />
                </S.CheckBox>{" "}
                스크래치
              </label>
              <label htmlFor="dent">
                <S.CheckBox>
                  <input
                    type="checkbox"
                    id="dent"
                    {...register("type", { required: true })}
                    value="dent"
                  />{" "}
                  <CheapfakeImg name="nocheck" type="png" />
                </S.CheckBox>{" "}
                찌그러짐
              </label>
            </div>
            <div>
              <label htmlFor="crack">
                <S.CheckBox>
                  <input
                    type="checkbox"
                    id="crack"
                    {...register("type", { required: true })}
                    value="crack"
                  />{" "}
                  <CheapfakeImg name="nocheck" type="png" />
                </S.CheckBox>{" "}
                파손
              </label>
              <label htmlFor="deformation">
                <S.CheckBox>
                  <input
                    type="checkbox"
                    id="deformation"
                    {...register("type", { required: true })}
                    value="deformation"
                  />{" "}
                  <CheapfakeImg name="nocheck" type="png" />
                </S.CheckBox>{" "}
                이격
              </label>
            </div>
            <div>
              <label>
                <S.CheckBox>
                  <input
                    type="checkbox"
                    {...register("type", { required: true })}
                    value="otherDamage"
                  />{" "}
                  <CheapfakeImg name="nocheck" type="png" />
                </S.CheckBox>{" "}
                기타
              </label>
            </div>
          </div>
        </S.Item>

        <S.Item>
          <label className="FormLabel" htmlFor="content">
            손상내용
            <span>*</span>
          </label>
          <input
            {...register("content", { required: true })}
            id="content"
            placeholder="(예시) 앞범퍼에 스크래치가 나서 도장을 함"
            className="FormInput"
          />
        </S.Item>
      </S.CarForm>
    </S.ClaimBox>
  );
};

export default CarClaimForm;
