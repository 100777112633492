type ImageType = "png" | "svg" | "jpg";

type IconType = {
  name: string;
  type: string;
};

const CheapfakeImg = ({ name, type }: IconType) => {
  return (
    <img
      src={process.env.REACT_APP_PUBLIC_URL + `/icon/${name}.${type}`}
      alt="icon"
    />
  );
};

export default CheapfakeImg;
