import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import Main from "./Main";
import MainPage from "./Features/Main/MainPage";
import DetectionPage from "./Features/Detection/DetectionPage";
import WhyPage from "./Features/Why/WhyPage";
import TechPage from "./Features/Tech/TechPage";
import MobilePage from "./Mobile/MobilePage";
import TagManager from "react-gtm-module";

const AppRouter = () => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: 767px)`);

    const handleChange = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addEventListener("change", handleChange);

    // 초기값 설정
    setIsMobile(mediaQuery.matches);

    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  // 페이지 이동 시 트래킹
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        pagePath: location.pathname,
        pageTitle: document.title,
      },
    });
  }, [location]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      children: isMobile
        ? [
            {
              path: "",
              element: <MobilePage />, // 모바일 페이지
            },
          ]
        : [
            // PC 및 태블릿 페이지
            {
              path: "",
              element: <MainPage />,
            },
            {
              path: "detection",
              element: <DetectionPage />,
            },
            {
              path: "why",
              element: <WhyPage />,
            },
            {
              path: "technology",
              element: <TechPage />,
            },
          ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
