import styled from "styled-components";
import { colors } from "../../../Utils/colors";

export const S = {
  Container: styled.div`
    display: flex;
    padding: 44px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    box-sizing: border-box;
  `,
  NormalText: styled.span`
    white-space: pre-line;
    color: ${colors[900]};
    text-align: center;

    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    letter-spacing: -0.612px;
  `,
  PurpleText: styled.span`
    color: ${colors.primary};

    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.85px;
  `,
};
