import React from "react";
import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { S } from "../\bStyles/explainstyle";
import CheapfakeImg from "../../../Common/CheapfakeImg/CheapfakeImg";

const ExplainBox = () => {
  return (
    <S.Container>
      <S.TxtBox>
        <p>
          클레임 데이터 간 맥락을 종합적으로 판단하는
          <br />
          똑똑한 위조 탐지 AI 솔루션
        </p>
        <p>AI 기술을 활용해 청구 문서 조작 사기를 조기 적발할 수 있어요</p>
      </S.TxtBox>
      <S.ImgBox>
        <CheapfakeImg name="intro3" type="png" />
      </S.ImgBox>
    </S.Container>
  );
};

export default ExplainBox;
