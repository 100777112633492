import React from "react";
import styled from "styled-components";
import { colors } from "../../../Utils/colors";
import { S } from "../\bStyles/titlestyle";

const WhyTitleBox = () => {
  return (
    <S.Container>
      <S.TxtBox>
        <p>
          조작된 청구 문서를 탐지하고
          <br />
          부당 청구를 방지해요
        </p>
        <p>
          SafeClaim은 보험금 부당 청구를 방지하기 위해 설계된 AI 기반 위조 탐지
          서비스에요. AI가 청구 문서를 신속하게 분석하고 위조 여부를 자동으로
          감지해 담당자에게 알리는 모니터링 시스템을 제공해요. SafeClaim은
          위조된 서류를 찾아내 부당 청구를 막고, 클레임 데이터 조작으로 인한
          보험 업계의 경제적 손실을 줄이며 사회 전반의 신뢰도를 높이는 것을
          목표로 해요.
        </p>
      </S.TxtBox>
      <S.ItemBox>
        <S.Item>
          <div className="Subject">
            <div className="NumBox">1</div>
            <p className="Text">해마다 증가하는 보험사기 적발 금액</p>
          </div>
          <S.ImgBox
            backgroundImage={
              process.env.REACT_APP_PUBLIC_URL + "/icon/intro1.png"
            }
          />
          <p className="Additional">금융감독원, 2023</p>
        </S.Item>
        <S.Item>
          <div className="Subject">
            <div className="NumBox">2</div>
            <p className="Text">갈수록 교묘해지는 보험사기 수법</p>
          </div>
          <S.ImgBox
            backgroundImage={
              process.env.REACT_APP_PUBLIC_URL + "/icon/intro2.png"
            }
          />
        </S.Item>
      </S.ItemBox>
    </S.Container>
  );
};

export default WhyTitleBox;
