import React, { useEffect } from "react";
import styled from "styled-components";
import TiTleBox from "./Component/TiTleBox";
import TechContent from "./Component/TechContent";

import { commonStyle } from "../../Common/commonStyle";
import { colors } from "../../Utils/colors";

const S = {
  TechContainer: styled.div`
    ${commonStyle.commonPageContainer}
  `,
  BackgroundContainer: styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
  `,
};
const TechPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <S.TechContainer>
      <S.BackgroundContainer color={colors.white}>
        <TiTleBox />
      </S.BackgroundContainer>
      <S.BackgroundContainer color={colors.bg2}>
        <TechContent />
      </S.BackgroundContainer>
    </S.TechContainer>
  );
};

export default TechPage;
